import React, { useState, useEffect } from "react";
import VideojsPlayer from '../components/VideoJsPlayer'
import YouPlayer from '../components/YouPlayer';
import TapToPlay from '../images/TapToPlay.svg'
import { siteConstants } from '../constants'
const LiveSessionVideo = (props) => {
    const [duration, setDuration] = useState(0);
    const [lastviewvideotime,setLastviewvideotime] = useState(0);
    const [changeVideoTime,setChangeVideiTime] = useState(0)
    const [isUpdatelastviewvideotime,setIsUpdatelastviewvideotime] = useState(false);
    const [currentPosition, setCurrentPosition] = useState(0);
    useEffect(() => {
       // if(props.session_video_set.length>0){
          getContinueWatchigTime()
        //}
        
      }, [props.session_video_set]);
      
    const onvideoend = () => {

    }
    useEffect(()=>{
     setIsUpdatelastviewvideotime(true)
    },[lastviewvideotime])
    const onvideoseeking = () => {

    }
    
    
      const handleDuration = (duration) => {
        setDuration(duration);
      };
      let lastCallTime = 0;
      const ontimeupdate = (totalduration, currentTime) => {
        
        var currentSendTime=parseInt(currentTime);
        if(currentSendTime-lastCallTime<0){
          lastCallTime = currentSendTime;
          
        }
        if(currentTime>1 && (currentSendTime-lastCallTime>119) && (currentSendTime%120==0)){
          
          lastCallTime = currentSendTime;
          updateVideoTime(lastCallTime)
          
        }
        setCurrentPosition(currentTime);
    };
    const updateVideoTime = async (vtime)=>{
      try{
        var data ={"user_id":props.user_data.user_id,"session_id":props.session_video_set.id,"video_time":vtime};
        let requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_BATCH_VIDEO_API_KEY
          },
          
          body: JSON.stringify(data),
        };
        let r = await fetch(process.env.REACT_APP_BATCH_VIDEO_API+"store", requestOptions);
      }catch(e){

      }
       
      }
      const getContinueWatchigTime = async ()=>{
        try{

        
        if(props.session_video_set.hasOwnProperty('is_upcoming') && !(props.session_video_set.is_upcoming)&&props.session_video_set.hasOwnProperty('recorded_url') && (props.session_video_set.recorded_url!='')){
          
        let requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_BATCH_VIDEO_API_KEY
          }
        };
        let r = await fetch(process.env.REACT_APP_BATCH_VIDEO_API+"batch/" + props.session_video_set.id+"/"+props.user_data.user_id, requestOptions)
        let r1 = await r.json()
        
        props.setLastviewvideotime(r1.data.time)
        props.setUpdateloadvideotime(true)
    }else{
      
      if(props.session_video_set.length>0){
        props.setUpdateloadvideotime(true)

      }
    }
  }catch(e){
     props.setUpdateloadvideotime(true)
    }

      }
    if (props.session_video_set.hasOwnProperty('is_upcoming') && (props.session_video_set.is_upcoming)) {
        if(props.session_video_set.hasOwnProperty('scheduled_url') && (props.session_video_set.scheduled_url!='')){
            return (<div className="live_class_wrapper_box">
                <object data={props.session_video_set.scheduled_url} width="100%" height="100%"></object>
          </div>)
        }else{
            return (<div className="live_class_wrapper_box">
                <div className="live_class_inner_wrapper_box text-left"> 
                <p style={{ fontSize:'15px', lineHeight:'30px', color:'#404040' }}>
                    Dear student,<br/>

                    The session named is {props.session_video_set.mame} supposed to go LIVE on {props.session_video_set.start_date} at {props.session_video_set.start_time}. You will receive the link to join the session one day before the session from <a href="mailto:support@elearnmarkets.com">support@elearnmarkets.com</a>.<br/>

                    *Don't forget to check your Spam folder in case you don't get the email in your inbox.<br/>

                    For any further assistance, feel free to reach our Customer Delight Team at <a href="tel:+9051622255" target="_blank">9051622255</a> or email at <a href="mailto:support@elearnmarkets.com" target="_blank">support@elearnmarkets.com</a>
                </p>
          </div>
          
        
        </div>)
        }
        
    }else if(props.session_video_set.hasOwnProperty('is_upcoming') && !(props.session_video_set.is_upcoming)){
        if(props.session_video_set.hasOwnProperty('recorded_url') && (props.session_video_set.recorded_url!='')){
            let video_link = props.session_video_set.recorded_url
            if (video_link.match(/(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/)) {
                return (<div className="section-class" style={{border: 0}}><YouPlayer video_link={video_link} /></div>)
            } else {
                return (<div className="section-class">
                    
                    {props.updateloadvideotime &&(
                        <VideojsPlayer
                        src={
                          video_link
                        }
                        onloadeddata={handleDuration}
                        ontimeupdate={ontimeupdate}
                        lastPlayTime={props.lastviewvideotime}
                        customText={props.user_data.email}
                      />
                    )

                    }
                    
                </div>
                )
            }
        }else{
            return (<div className="live_class_wrapper_box">
                <p>Dear Participant,</p>
            <p> Please wait for upload recording session. </p>
            
            </div>) 
        }
    } else{
        return (<div className="live_class_wrapper_box text-center">
            <div className="live_class_inner_wrapper_box text-center">
                <img src={TapToPlay} style={{ width:'110px', marginBottom:'20px'}}/>               
                <p className='select_your_batch'>
                        Select your batch and get started with your classes. <br/>You can also view your past class recordings here.
                </p>
          </div>
          </div>)
    }
}

export default LiveSessionVideo
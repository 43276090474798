import React from 'react'

const TestSectionRightBar = (props) => {

    function setCurrentQuestion(item, index) {
        if (props.current_question_no !== index) {
            props.setCurrentQuestion({is_next:2},index)
        }
    }

    return (
        <div className="right-section-for-elearn for-test-section">
            <div className="side-menu-for-lms ">
                <div className="number-of-questions-box">
                    <h2>
                        Questions
                        </h2>
                    <div class="total-question-number-box">
                        {
                            props.course_test_info[0].glossary_questions.map((item, index) => (
                                <button className={index === props.current_question_no ? "current-q" : item.is_attempted === 1 ? "Attempted-q" : ''} onClick={() => setCurrentQuestion(item, index)}>{index + 1}</button>
                            ))
                        }
                    </div>
                    <div className="question-Attempted-box">
                        <ul>
                            <li>
                                <span className="Attempted-q"></span> Attempted
                                </li>
                            <li>
                                <span></span> Not Attempted
                                </li>
                            <li>
                                <span className="current-q"></span> Current
                                </li>
                        </ul>
                    </div>
                </div>
                <p className="text-center mt-4 mb-4">
                    <button onClick={() => props.cancelTest()} className="btn btn-default">Cancel and Exit</button>
                </p>
            </div>
        </div>
    )
}

export default React.memo(TestSectionRightBar)
import React from 'react'
import Layout from '../Layout'

export const NotFound = () => {
    return (
        <Layout>
            Page not found
        </Layout>
    )
}

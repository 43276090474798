import React, { useEffect, useState } from 'react'
import Layout from './Layout';
import { useDispatch, useSelector } from 'react-redux'
import UserCourse from "./Element/UserCourse";
import { myaccountAction } from './redux/actions/myaccountAction'
import ErrorModal from './Element/ErrorModal'
import Loader from "./Element/Loader"

const MyCourses = (props) => {

  const myaccount = useSelector(state => state.myaccount)

  const dispatch = useDispatch()
  const [currentCourse, setCurrentCourse] = useState({})
  const [show, setShow] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    getUserCourse()
  }, [])

  const getUserCourse = async () => {
    try {
      await dispatch(
        myaccountAction.getMyCourses()
      );
    } catch (err) {
      setIsError(true)
      throw new Error(err)
    }
  }
  const handleCurrentCourse = () => {
    return currentCourse;
  }
  const getShow = () => {
    return show;
  }

  return (

    <Layout nameprefix="My" name="Courses">
      {
        !myaccount.loading && !isError &&
        <UserCourse
          feedData={myaccount.my_course_data}
          name={``}
          setcurrentCourse={setCurrentCourse}
          getcurrentCourse={handleCurrentCourse}
          getShow={getShow}
          setShow={setShow}
          loader={false}
        />
      }
      {myaccount.loading && !isError &&
        <Loader
          is_loading={myaccount.loading && !isError}
        />

      }
      {
        isError &&
        <ErrorModal
          error={isError}
        />
      }
    </Layout>

  )
}

export default MyCourses;

/* eslint-disable array-callback-return */
import initialstate from '../initialstate'

import { actionconstants } from '../actionconstants'

export default function courseReducer(state = initialstate, action) {

    switch (action.type) {
        case actionconstants.LOADING_COURSE_DATA:
            return {
                ...state,
                course_initial_data_loading: true
            }
        case actionconstants.LOADING_UNIT_DATA:
            return {
                ...state,
                course_unit_loading: true,
                loading_chapter_id: action.payload.loading_chapter_id,
            }
        case actionconstants.LOADING_COURSE_DATA_SUCCESS:
            let course_data = action.payload.course_initial_data.course_data
            let unit_completed = action.payload.user_unit
            let is_completed
            let initial_selected_course_unit = []
            course_data.map((sec) => {
                if (sec.id === action.payload.course_initial_data.section_selected) {
                    sec.course_chapters.map((chap) => {
                        if (chap.id === action.payload.course_initial_data.chapter_selected) {
                            chap.unit.map((un) => {
                                is_completed = unit_completed.find((arr) => { return un.id === arr })
                                if (is_completed === un.id) {
                                    un.is_completed = true
                                }
                                if (un.id === action.payload.course_initial_data.unit_selected) {
                                    initial_selected_course_unit = un
                                }
                            })
                        }
                    })
                }
            })
            localStorage.setItem('current_object', JSON.stringify({
                section_selected: action.payload.course_initial_data.section_selected,
                chapter_selected: action.payload.course_initial_data.chapter_selected,
                unit_selected: action.payload.course_initial_data.unit_selected,
            }))
            return {
                ...state,
                course_initial_data_loading: false,
                course_name: action.payload.course_info.name,
                has_batch: action.payload.course_info.has_batches,
                course_data: course_data,
                section_selected: action.payload.course_initial_data.section_selected,
                chapter_selected: action.payload.course_initial_data.chapter_selected,
                unit_selected: action.payload.course_initial_data.unit_selected,
                unit_data: action.payload.course_initial_data.unit_data,
                unit_completed_data: unit_completed,
                course_completion_percentage_info: action.payload.course_completion_percentage_info,
                selected_course_unit: initial_selected_course_unit,
                user_data:action.payload.userData
            }
        case actionconstants.SECTION_OPEN:
            let c_sec = [...state.course_data]
            c_sec.map((sec, i) => {
                if (parseInt(i) === parseInt(action.payload)) {
                    sec.showChapter = (sec.showChapter === true) ? false : true;
                }
            })
            return {
                ...state,
                course_data: c_sec
            }
        case actionconstants.CHAPTER_OPEN_SUCCESS:
            let c_sec1 = [...state.course_data]
            unit_completed = [...state.unit_completed_data]
            c_sec1.map((sec, i) => {
                if (i === action.payload.section_index) {
                    sec.course_chapters.map(async (chap, ci) => {
                        if (ci === action.payload.chapter_index) {
                            if (!chap.is_unit_loaded) {
                                let unit_data = action.payload.unit_data
                                unit_data.map((un) => {
                                    is_completed = unit_completed.find((arr) => { return un.id === arr })
                                    if (is_completed === un.id) {
                                        un.is_completed = true
                                    }
                                })
                                chap.unit = unit_data
                                chap.is_unit_loaded = true
                            }
                            chap.showUnit = (chap.showUnit === true) ? false : true;
                        }
                    })
                }
            })
            return {
                ...state,
                course_unit_loading: false,
                course_data: c_sec1
            }
        case actionconstants.SET_OPEN_DATA:
            c_sec1 = [...state.course_data]
            let selected_unit = []
            let selected_course_unit = []
            c_sec1.map((sec, i) => {
                if (parseInt(sec.id) === parseInt(action.payload.section_id)) {
                    sec.course_chapters.map((chap) => {
                        if (parseInt(chap.id) === parseInt(action.payload.chapter_id)) {
                            if (action.payload.chapter_to_be_shown) {
                                chap.showUnit = true
                            }
                            chap.unit.map((un) => {
                                if (parseInt(un.id) === parseInt(action.payload.unit_id)) {
                                    selected_unit = un.filecontent
                                    selected_course_unit = un
                                }
                            })
                        }
                    })
                }
            })
            localStorage.setItem('current_object', JSON.stringify({
                section_selected: action.payload.section_id,
                chapter_selected: action.payload.chapter_id,
                unit_selected: action.payload.unit_id,
            }))
            return {
                ...state,
                section_selected: action.payload.section_id,
                chapter_selected: action.payload.chapter_id,
                unit_selected: action.payload.unit_id,
                unit_data: selected_unit,
                selected_course_unit: selected_course_unit
            }
        case actionconstants.USER_NOT_LOGGED_IN:
            return {
                ...state,
                user_not_logged_in: true,
                course_initial_data_loading: false
            }
        case actionconstants.COURSE_UNIT_COMPLETE:

            let { section_id, chapter_id, unit_id } = action.payload
            let completion_percentage
            let course_completion_percentage_info = [...state.course_completion_percentage_info]
            c_sec1 = [...state.course_data]
            let is_already_completed = false

            c_sec1.map((sec) => {
                if (sec.id === section_id) {
                    sec.course_chapters.map((chap) => {
                        if (chap.id === chapter_id) {
                            chap.unit.map((un) => {
                                if (parseInt(un.id) === parseInt(unit_id)) {
                                    if (!(un.hasOwnProperty('is_completed') && un.is_completed === true)) {
                                        un.is_completed = true
                                    } else {
                                        is_already_completed = true
                                    }
                                }
                            })
                        }
                    })
                    if (is_already_completed === false) {

                        let { totalUnit, totalUnitLearn, totalPercentage } = (sec.section_completion_percentage)
                        if (totalPercentage < 100) {
                            totalUnitLearn = totalUnitLearn + 1
                            totalPercentage = Math.round(totalUnitLearn / totalUnit * 100)
                            completion_percentage = { totalUnit: totalUnit, totalUnitLearn: totalUnitLearn, totalPercentage: totalPercentage }
                            sec.section_completion_percentage = completion_percentage

                            course_completion_percentage_info[0].totalUnitLearn = course_completion_percentage_info[0].totalUnitLearn + 1
                            course_completion_percentage_info[0].totalPercentage = Math.round(course_completion_percentage_info[0].totalUnitLearn / course_completion_percentage_info[0].totalUnit * 100)
                            course_completion_percentage_info[0].totalPercentage = course_completion_percentage_info[0].totalPercentage > 100 ? 100 : course_completion_percentage_info[0].totalPercentage
                        }
                    }
                }
            })

            return {
                ...state,
                course_data: c_sec1,
                course_completion_percentage_info: course_completion_percentage_info
            }

        case actionconstants.COURSE_BATCH_LOADING:
            return {
                ...state,
                course_batch_loading: true
            }
        case actionconstants.COURSE_BATCH_LOADING_COMPLETE:
            return {
                ...state,
                course_batch_loading: false,
                course_batch_loaded: true,
                course_batch_data: action.payload
            }
        case actionconstants.COURSE_BATCH_SESSION_LOADING:
            return {
                ...state,
                course_batch_session_loading: true
            }

        case actionconstants.COURSE_BATCH_SESSION_LOADING_COMPLETE:
            let tmp_b = [...state.course_batch_data]
            let batch_id = action.payload.batch_id
            tmp_b.map((it, i) => {
                if (it.id === batch_id) {
                    it.batch_session_loaded = true
                    it.batch_session_opened = it.batch_session_opened ? false : true
                    it.batch_sessions = [...action.payload.session_data]
                }
            })
            return {
                ...state,
                course_batch_session_loading: false,
                course_batch_data: tmp_b
            }
        case actionconstants.COURSE_BATCH_OPEN_SESSION:
            tmp_b = [...state.course_batch_data]
            tmp_b.map((it, i) => {
                if (i === action.payload.batch_index) {
                    it.batch_sessions.map((sess, idx) => {
                        if (idx === action.payload.session_index) {
                            sess.is_open = (sess.is_open) ? false : true
                        }
                    })
                }
            })
            return {
                ...state,
                course_batch_data: tmp_b
            }
        case actionconstants.COURSE_SESSION_VIDEO_SET:
            return {
                ...state,
                session_video_set: action.payload
            }
        case actionconstants.SET_VIDEO_TIME:
            c_sec1 = [...state.course_data]
            c_sec1.map((sec) => {
                if (sec.id === action.payload.section_selected) {
                    sec.course_chapters.map((chap) => {
                        if (chap.id === action.payload.chapter_selected) {
                            chap.unit.map((un) => {
                                if (parseInt(un.id) === parseInt(action.payload.unit_selected)) {
                                    un.filecontent.video_time = action.payload.time
                                }
                            })
                        }
                    })
                }
            })

            return {
                ...state,
                course_data: c_sec1,
            }
        default:
            return {
                ...state
            }
    }
}
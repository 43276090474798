import React, { Component } from 'react'
import AuthorLmsComponent from './components/AuthorLmsComponent'

class AuthorLms extends Component {

  constructor(props) {
    super(props)
    const { match: { params } } = this.props;
    localStorage.setItem('course_id', params.id)
    this.state = {
      data: [],
      redirectToReferrer: false,
      name: '',
    };
  }
  render() {
    return (
      <AuthorLmsComponent />
    )
  }
}

export default AuthorLms;

import initialstate from "../initialstate";
import { myAccountActionConstants } from "../myAccountActionConstants";

export default function accountReducer(state = initialstate, action) {
  switch (action.type) {
    case myAccountActionConstants.LOADING_MYACCOUNT_COURSE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case myAccountActionConstants.LOADING_MYACCOUNT_COURSE_COMPLETE:
      return {
        ...state,
        my_course_data: action.payload,
        loading: false,
      };
    case myAccountActionConstants.LOADING_MYACCOUNT_WEBINAR_LOADING:
      return {
        ...state,
        firstwebinarloading: true,
      };
    case myAccountActionConstants.LOADING_MYACCOUNT_WEBINAR_COMPLETE:
      let user_webinar_data = [];

      if (action.pagination.prevPage) {
        user_webinar_data = [...state.my_webinar_data, ...action.payload];
      } else {
        user_webinar_data = action.payload;
      }

      return {
        ...state,
        my_webinar_data: user_webinar_data,
        my_webinar_paging: action.pagination,
        firstwebinarloading: false,
      };

    case myAccountActionConstants.LOADING_MYACCOUNT_WEBINAR_LOADING_PAGING:
      return {
        ...state,
        pageloading: true,
      };
    case myAccountActionConstants.LOADING_MYACCOUNT_WEBINAR_COMPLETE_PAGING:
      let user_webinar_data_paging = [];

      if (action.pagination.prevPage) {
        user_webinar_data_paging = [
          ...state.my_webinar_data,
          ...action.payload,
        ];
      } else {
        user_webinar_data_paging = action.payload;
      }

      return {
        ...state,
        my_webinar_data: user_webinar_data_paging,
        my_webinar_paging: action.pagination,
        pageloading: false,
      };

    case myAccountActionConstants.LOADING_MYACCOUNT_UPCOMING_WEBINAR_LOADING:
      return {
        ...state,
        loading: true,
      };
    case myAccountActionConstants.LOADING_MYACCOUNT_UPCOMING_WEBINAR_COMPLETE:
      return {
        ...state,
        my_upcoming_webinar_data: action.payload,
        loading: false,
      };

    case myAccountActionConstants.LOADING_MYACCOUNT_RATING_LOADING:
      return {
        ...state,
        rating_data_loading: true,
      };
    case myAccountActionConstants.LOADING_MYACCOUNT_RATING_COMPLETE:
      return {
        ...state,
        rating_data: action.payload,
        rating_data_loading: false,
      };
    case myAccountActionConstants.LOADING_USER_INFO:
      return {
        ...state,
        user_data_loading: true,
      };

    case myAccountActionConstants.LOADING_USER_INFO_COMPLETE:
      return {
        ...state,
        user_data: action.payload,
        user_data_loading: false,
      };
      case myAccountActionConstants.LOADING_USER_DETAILS:
      return {
        ...state,
        user_all_data_loading: true,
      };

    case myAccountActionConstants.LOADING_USER_DETAILS_COMPLETE:
      return {
        ...state,
        user_all_data: action.payload,
        user_all_data_loading: false,
      };
    case myAccountActionConstants.USER_NOT_LOGGGED_IN:
      return {
        ...state,
        user_not_logged_in: true,
      };
    case myAccountActionConstants.LOADING_MYACCOUNT_ALL_RATING_LOADING:
      return {
        ...state,
        user_rating_loading: true,
      };
    case myAccountActionConstants.LOADING_MYACCOUNT_ALL_RATING_COMPLETE:
      return {
        ...state,
        all_rating_data: action.payload,
        all_rating_data_paging: action.pagination,
        user_rating_loading: false,
      };

    case myAccountActionConstants.LOADING_MYACCOUNT_ALL_PAGING_RATING_LOADING:
      return {
        ...state,
        user_rating_paging_loading: true,
      };
    case myAccountActionConstants.LOADING_MYACCOUNT_ALL_PAGING_RATING_COMPLETE:
        let all_rating_data_paging = [];

        if (action.pagination.prevPage) {
            all_rating_data_paging = [
            ...state.all_rating_data,
            ...action.payload,
          ];
        } else {
            all_rating_data_paging = action.payload;
        }
  
        return {
          ...state,
          all_rating_data: all_rating_data_paging,
          all_rating_data_paging: action.pagination,
          user_rating_paging_loading: false,
        };
    case myAccountActionConstants.LOADING_MYACCOUNT_ALL_RATING_LOADING:
      return {
        ...state,
        avarage_rating_data_loading: true,
      };
    case myAccountActionConstants.LOADING_MYACCOUNT_AVARAGE_RATING_COMPLETE:
      return {
        ...state,
        avarage_rating_data: action.payload,
        avarage_rating_data_loading: false,
        my_rating_review:action.payload.myReview
      };
      case myAccountActionConstants.LOADING_MYACCOUNT_LIKE_LOADING:
      return {
        ...state,
        like_loading: true,
      };
      case myAccountActionConstants.LOADING_MYACCOUNT_LIKE_COMPLETE:
        let all_data=[]
        let temp_arr={}
        if(action.payload.data.success==1){
          all_data = [
            ...state.all_rating_data
          ];
          temp_arr = {...all_data[action.payload.index]}
          if(action.payload.type==1){
            temp_arr.total_like = temp_arr.total_like+1
          }else{
            temp_arr.total_spam = temp_arr.total_spam+1
          }
          
          all_data[action.payload.index]=temp_arr
        }else{
          all_data = [
            ...state.all_rating_data
          ];
        }
        return {
          ...state,
          all_rating_data:all_data,
          like_loading: false,
        };
    default:
      return {
        ...state,
      };
  }
}

import React, { useState, useLayoutEffect, useEffect } from 'react'
import YouPlayer from './YouPlayer'

import parse from 'html-react-parser'
import { Modal } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay'
import { BarLoader } from 'react-spinners'
import MyVideojsPlayer from './MyVideojsPlayer'

import ShowQuestionTest from './ShowQuestionTest'
import CourseTestResult from './CourseTestResult'

import Game from '../Element/Game'

const AuthorLmsSection = (props) => {

    useEffect(() => {
    }, [props.unit_selected])

    useLayoutEffect(() => {

        if (props.unit_data.glossary_id > 0) {
            props.gettestdetail(props.unit_data.glossary_id, props.unit_selected)
        }

    }, [props.unit_data.glossary_id])

    const [bgdata, setBgdata] = useState("loading...")

    const RenderHtml = (cprops) => {
        return (
            <div className="section-class-html">
                {
                    parse(`
                        ${cprops.html_data}
                    `)
                }
            </div>
        )
    }
    const RenderBlogHtml = (cprops) => {
        return (
            <div className="section-class" style={{ overflowY: 'scroll' }}>
                {
                    cprops.data && <RenderHtml
                        html_data={cprops.data}
                    />
                }

            </div>
        )
    }
    async function getBg(url) {
        const dd = await props.getbloghtml(url)
        if (dd) {
            setBgdata(dd)
        }
    }


    function startTestAll(time_alloted) {
        props.startClock(time_alloted)
        props.startCourseTest()
    }




    if (props.unit_data) {
        if (parseInt(props.unit_data.file_type_id) === 2) {
            let video_link = (props.unit_data.unit_url_new !== null && props.unit_data.unit_url_new.length > 0) ? props.unit_data.unit_url_new : props.unit_data.unit_url

            if (video_link.match(/(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/)) {
                return (
                    <div>
                        <p className="complete-unit-name">{props.selected_course_unit.name}{props.selected_course_unit.is_completed}</p>

                        <div className="section-class" style={{ border: 0 }}>
                            <YouPlayer video_link={video_link} onEnded={props.onvideoend} />
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <p className="complete-unit-name">{props.selected_course_unit.name}{props.selected_course_unit.is_completed}</p>

                        <div className="section-class">
                            <MyVideojsPlayer
                                unit_selected={props.unit_selected}
                                src={video_link}
                                onvideoseeking={props.onvideoseeking}
                                onvideoend={props.onvideoend}
                                onvideoplaying={props.onvideoplaying}
                            />
                        </div>
                    </div>
                )
            }
        }
        else if (parseInt(props.unit_data.file_type_id) === 11) {

            return (
                <div>
                    <p className="complete-unit-name">{props.selected_course_unit.name}{props.selected_course_unit.is_completed}</p>
                    <div className="section-class">
                        <object id="videoObject" data={props.unit_data.unit_url} width="100%" height="100%" type="application/pdf">
                            Unsupported Browser: Please try to open this in Google Chrome or Mozilla Firefox
                        </object>
                    </div>
                </div>
            )
        }
        else if (parseInt(props.unit_data.file_type_id) === 12) {
            return (
                <div>
                    <p className="complete-unit-name">{props.selected_course_unit.name}{props.selected_course_unit.is_completed}</p>
                    <div className="section-class" style={{ overflowY: 'scroll' }}>
                        <RenderHtml html_data={props.unit_data.unit_html} />
                    </div>
                </div>
            )
        } else if (parseInt(props.unit_data.file_type_id) === 4 || parseInt(props.unit_data.file_type_id) === 5) {
            let file_url = "https://d24uab5gycr2uz.cloudfront.net/uploads/course_doc/" + props.unit_data.unit_url
            return (
                <div>
                    <p className="complete-unit-name">{props.selected_course_unit.name}{props.selected_course_unit.is_completed}</p>
                    <div className="section-class">
                        <object id="pdfObject" data={file_url} width="100%" height="100%" type="application/pdf">
                            Unsupported Browser: Please try to open this in Google Chrome or Mozilla Firefox
                        </object>
                    </div>
                </div>
            )
        } else if ((parseInt(props.unit_data.file_type_id) === 6) && props.unit_data.unit_url.match('https://www.elearnmarkets.com/blog/')) {
            return (
                <div>
                    <p className="complete-unit-name">{props.selected_course_unit.name}{props.selected_course_unit.is_completed}</p>
                    <RenderBlogHtml
                        getbloghtml={getBg(props.unit_data.unit_url)}
                        data={bgdata}
                    />
                </div>
            )
        } else if (parseInt(props.unit_data.file_type_id) === 3) {
            let file_url_download = "https://d24uab5gycr2uz.cloudfront.net/uploads/course_doc/" + props.unit_data.unit_url
            return (
                <div>
                    <p className="complete-unit-name">{props.selected_course_unit.name}{props.selected_course_unit.is_completed}</p>
                    <div className="section-class course-test" style={{ padding: '2rem', textAlign: 'center' }}>
                        <p>Please click the download button below in order to understand the concept in a better way.</p>
                        <a className="ask-new-question" href={file_url_download} target="_blank" rel="noopener noreferrer" style={{ marginTop: '1rem' }}>Download</a>
                    </div>
                </div>
            )
        } else if (parseInt(props.unit_data.file_type_id) === 7 || parseInt(props.unit_data.file_type_id) === 13) {
            return (
                <div>
                    {
                        !props.course_test_loading && props.course_test_data_success_value === 3 && props.course_completion_percentage_info[0].totalPercentage >= process.env.REACT_APP_COMPLETION_PERCENTAGE && (
                            <div className="section-class-test">
                                <div className="course-test" style={{ textAlign: 'center' }}>
                                    <div className="section-class-html">
                                        <img className="img-fluid" src={props.course_test_all_data.image_link} alt="certificate" />
                                        <p className="mt-2">Your certificate is generated - please download from below link</p>
                                    </div>
                                    <p className="mt-1">
                                        <a className="ask-new-question" target="_blank" href={props.course_test_all_data.certificate_link}>Download</a>
                                    </p>
                                </div>
                            </div>
                        )
                    }
                    {
                        !props.course_test_loading && props.course_test_data_success_value === 3 && props.course_completion_percentage_info[0].totalPercentage < process.env.REACT_APP_COMPLETION_PERCENTAGE && (
                            <div className="section-class-test">
                                <div className="course-test ">
                                    <p className="course-test-name">{props.selected_course_unit.name}</p>
                                    <div className="section-class-html">
                                        <p>Dear Participant,</p>

                                        <p>Please read the below instructions carefully:</p>

                                        <p>- The Certification Test shall become Active, only after {process.env.REACT_APP_COMPLETION_PERCENTAGE}% completetion of the course.</p>

                                        <p>- This Test can be taken as many times the student wants to appear.</p>

                                        <p>- The student needs to wait for 8 hours to reappear for the tests.</p>

                                        <p>- Passing Marks = {props.selected_course_unit.pass_mark}%</p>

                                        <p>- After passing the examination, student will receive an auto generated certificate.</p>

                                        <p>- Once the certificate is downloaded, the student cannot reappear for the test.</p>

                                        <p>In case of any further queries related, please connect to our Support Team at 9051622255.
                                        </p>
                                        {
                                            props.course_completion_percentage_info[0].totalPercentage >= process.env.REACT_APP_COMPLETION_PERCENTAGE &&
                                            <div style={{ textAlign: "center" }}>
                                                <button className="btn start-btn" onClick={() => startTestAll(props.course_test_info[0].time_alloted)}>Start Test</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        !props.course_test_loading && props.course_test_data_success_value === 2 && (
                            <div className="section-class-test">
                                <div className="course-test">
                                    <div className="section-class-html">
                                        <p>
                                            Dear Participant,
                                        </p>
                                        <p>You've already attempted this test.</p>
                                        <p>The details are as follows -</p>
                                        <p>
                                            Total Marks: {(props.course_test_all_data.data.glossary.glossary_questions.length * props.course_test_all_data.data.glossary.each_question_mark)}
                                        </p>
                                        <p>
                                            Marks Obtained: {props.course_test_all_data.data.marks_obtained}
                                        </p>
                                        <p>
                                            Remarks:
                                            {
                                                ((props.course_test_all_data.data.marks_obtained / (props.course_test_all_data.data.glossary.glossary_questions.length * props.course_test_all_data.data.glossary.each_queation_mark)) * 100).toFixed(2) >= 50 && (
                                                    <span>Congratulations you've passed the test</span>
                                                )
                                            }
                                            {
                                                ((props.course_test_all_data.data.marks_obtained / (props.course_test_all_data.data.glossary.glossary_questions.length * props.course_test_all_data.data.glossary.each_queation_mark)) * 100).toFixed(2) < 50 && (
                                                    <span>Sorry you failed the test</span>
                                                )
                                            }
                                        </p>

                                        <p>Contact our Support Team at support@elearnmarkets.com or Call at +91 9051622255 for any further assistance.</p>

                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        !props.course_test_loading && props.course_test_data_success_value === 4 && (
                            <div className="section-class-test">
                                <div className="course-test" style={{ textAlign: 'center' }}>
                                    <p style={{ marginTop: '2rem' }}>{props.course_test_all_data.msg}</p>
                                    <p>Attempted Date: {props.course_test_all_data.data.attempt_date}</p>
                                    <p>Next attempt time: {props.course_test_all_data.nextAttempt}</p>
                                </div>
                            </div>
                        )
                    }
                    {
                        !props.course_test_loading && props.course_test_data_success_value === 1 && (

                            <div className="section-class-test">
                                {
                                    !props.course_test_loading && !props.course_test_submit && (
                                        <div className="course-test ">
                                            <p className="course-test-name">{props.selected_course_unit.name}</p>
                                            {
                                                !props.isCourseTestStarted && parseInt(props.unit_data.file_type_id) === 7 &&
                                                <div className="section-class-html">
                                                    <RenderHtml
                                                        html_data={props.course_test_info[0].description}
                                                    />
                                                    <div style={{ textAlign: "center" }}>
                                                        <button className="btn start-btn" onClick={() => startTestAll(props.course_test_info[0].time_alloted)}>Start Test</button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                !props.isCourseTestStarted && parseInt(props.unit_data.file_type_id) === 13 &&
                                                <div className="section-class-html">
                                                    <p>Dear Participant,</p>

                                                    <p>Please read the below instructions carefully:</p>

                                                    <p>- The Certification Test shall become Active, only after {process.env.REACT_APP_COMPLETION_PERCENTAGE}% completetion of the course.</p>

                                                    <p>- This Test can be taken as many times the student wants to appear.</p>

                                                    <p>- The student needs to wait for 8 hours to reappear for the tests.</p>

                                                    <p>- Passing Marks = {props.selected_course_unit.pass_mark}%</p>

                                                    <p>- After passing the examination, student will receive an auto generated certificate.</p>

                                                    <p>- Once the certificate is downloaded, the student cannot reappear for the test.</p>

                                                    <p>In case of any further queries related, please connect to our Support Team at 9051622255.
                                                    </p>
                                                    {
                                                        props.course_completion_percentage_info[0].totalPercentage >= process.env.REACT_APP_COMPLETION_PERCENTAGE &&
                                                        <div style={{ textAlign: "center" }}>
                                                            <button className="btn start-btn" onClick={() => startTestAll(props.course_test_info[0].time_alloted)}>Start Test</button>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                props.isCourseTestStarted &&
                                                <div >
                                                    <ShowQuestionTest
                                                        timr={props.timeR}
                                                        question_data={props.course_test_info[0].glossary_questions}
                                                        course_test_id={props.course_test_info[0].id}
                                                        currentQuestion={props.current_question_no}
                                                        setCurrentQuestion={props.saveAnswerandSetCurrentQuestion}
                                                        onCompleteTest={props.setShowTestConfirmModel}
                                                        courseTestAnswerSelected={props.courseTestAnswerSelected}
                                                        next_question_loading={props.next_question_loading}
                                                        timeForEachQuestion={props.timeForEachQuestion}
                                                    />
                                                </div>
                                            }

                                            <Modal show={props.showTestConfirmModel}>
                                                <Modal.Header closeButton={false}>
                                                    <Modal.Title>Are you sure you want to submit the test? </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {
                                                        props.unit_data.file_type_id === 13 &&
                                                        <span>
                                                            This is a Certification Test. In case you fail, you will have to wait for next 8 hrs to reappear. <strong>Do you want to SUBMIT the test?</strong>
                                                        </span>
                                                    }
                                                    {
                                                        props.unit_data.file_type_id !== 13 &&
                                                        <span>
                                                            Please check if you have answered all the questions. If you click on the submit button below, the entire test will get submitted.
                                                        </span>
                                                    }

                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={() => props.setShowTestConfirmModel(false)}>
                                                        Cancel
                                                    </Button>
                                                    <Button variant="primary" onClick={props.finishTest}>
                                                        Yes, I want to submit the test
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    )
                                }
                                {
                                    !props.course_test_loading && props.course_test_submit && (
                                        <div>
                                            {
                                                props.course_test_result_loading && (
                                                    <div>
                                                        Generating result
                                                        <LoadingOverlay
                                                            active={props.course_test_result_loading}
                                                            spinner={<BarLoader />}
                                                        />
                                                    </div>
                                                )
                                            }
                                            {
                                                !props.course_test_result_loading && (
                                                    <div>
                                                        <CourseTestResult
                                                            test_name={props.course_test_info[0].name}
                                                            course_result_data={props.course_test_result}
                                                            course_test_info={props.course_test_info[0].glossary_questions}
                                                        />

                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                    {
                        props.course_test_loading && (
                            <div className="section-class-test">
                                <div className="course-test">
                                    <div style={{ marginTop: '130px' }}>
                                        <LoadingOverlay
                                            active={props.course_test_loading}
                                            spinner={<BarLoader />}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div >
            )
        } else if (parseInt(props.unit_data.file_type_id) === 9 || parseInt(props.unit_data.file_type_id) === 6) {
            return (
                <div>
                    <p className="complete-unit-name">{props.selected_course_unit.name}{props.selected_course_unit.is_completed}</p>
                    <div className="section-class">
                        <object id="pdfObject" data={props.unit_data.unit_url} width="100%" height="100%" type="application/pdf">
                            Unsupported Browser: Please try to open this in Google Chrome or Mozilla Firefox
                        </object>
                    </div>
                </div>
            )
        } else if (parseInt(props.unit_data.file_type_id) === 10) {
            return (
                <Game
                    word_glossary_id={props.unit_data.word_glossary_id}
                />)
        } else if (parseInt(props.unit_data.file_type_id) === 1) {
            let file_url_download = "https://d24uab5gycr2uz.cloudfront.net/uploads/course_doc/" + props.unit_data.unit_url
            return (
                <div>
                    <p className="complete-unit-name">{props.selected_course_unit.name}{props.selected_course_unit.is_completed}</p>
                    <div className="section-class">
                        <div style={{ height: '100%', overflowY: 'scroll' }}>
                            <img className="img-fluid" src={file_url_download} alt="" />
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="section-class">
                    <p>Please select Unit to continue learning</p>
                </div>
            )
        }
    }

}

export default React.memo(AuthorLmsSection)
import React from 'react';

function WorkshopIcon() {
    return (
<svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor">
<path d="m458.394 412.001c7.87-9.516 12.606-21.716 12.606-35.001 0-30.327-24.673-55-55-55-5.2 0-10.228.741-15 2.096v-74.096h5c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15h-5v-45c0-3.979-1.58-7.794-4.394-10.606l-20-20c-5.857-5.857-15.355-5.857-21.213 0s-5.858 15.355 0 21.213l15.607 15.606v38.787h-21.188c-4.89-30.698-24.538-56.537-51.418-69.999 7.87-9.516 12.606-21.716 12.606-35.001 0-30.327-24.673-55-55-55s-55 24.673-55 55c0 13.285 4.736 25.485 12.607 35.001-26.881 13.462-46.528 39.301-51.418 69.999h-56.189c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15h5v74.096c-4.772-1.355-9.8-2.096-15-2.096-30.327 0-55 24.673-55 55 0 13.285 4.736 25.485 12.607 35.001-31.161 15.605-52.607 47.843-52.607 84.999 0 8.284 6.716 15 15 15h480c8.284 0 15-6.716 15-15 0-37.156-21.446-69.394-52.606-84.999zm-17.394-35.001c0 13.785-11.215 25-25 25s-25-11.215-25-25 11.215-25 25-25 25 11.215 25 25zm-210-322c0-13.785 11.215-25 25-25s25 11.215 25 25-11.215 25-25 25-25-11.215-25-25zm25 55c30.677 0 56.442 21.37 63.234 50h-126.468c6.792-28.63 32.557-50 63.234-50zm-135 80h270v30h-270zm250 60v95.436c-6.291 8.942-10 19.825-10 31.564 0 13.285 4.736 25.485 12.606 35.001-15.366 7.695-28.373 19.431-37.606 33.813-9.234-14.382-22.24-26.117-37.606-33.813 7.87-9.516 12.606-21.716 12.606-35.001 0-30.327-24.673-55-55-55s-55 24.673-55 55c0 13.285 4.736 25.485 12.607 35.001-15.366 7.695-28.373 19.431-37.607 33.813-9.234-14.382-22.24-26.117-37.607-33.813 7.871-9.516 12.607-21.716 12.607-35.001 0-11.74-3.709-22.623-10-31.564v-95.436zm-140 127c0-13.785 11.215-25 25-25s25 11.215 25 25-11.215 25-25 25-25-11.215-25-25zm-160 0c0-13.785 11.215-25 25-25s25 11.215 25 25-11.215 25-25 25-25-11.215-25-25zm-38.253 105c6.788-28.635 32.572-50 63.253-50s56.465 21.365 63.253 50zm160 0c6.788-28.635 32.572-50 63.253-50s56.465 21.365 63.253 50zm160 0c6.788-28.635 32.572-50 63.253-50s56.465 21.365 63.253 50z"/></g></svg>


    );
}
export default WorkshopIcon;
export const myAccountActionConstants = {
    LOADING_MYACCOUNT_COURSE_LOADING: 'LOADING_MYACCOUNT_COURSE',
    LOADING_MYACCOUNT_COURSE_COMPLETE: 'LOADING_MYACCOUNT_COURSE_COMPLETE',
    LOADING_MYACCOUNT_WEBINAR_LOADING: 'LOADING_MYACCOUNT_WEBINAR',
    LOADING_MYACCOUNT_WEBINAR_COMPLETE: 'LOADING_MYACCOUNT_WEBINAR_COMPLETE',
    LOADING_MYACCOUNT_WEBINAR_LOADING_PAGING: 'LOADING_MYACCOUNT_WEBINAR_PAGING',
    LOADING_MYACCOUNT_WEBINAR_COMPLETE_PAGING: 'LOADING_MYACCOUNT_WEBINAR_COMPLETE_PAGING',
    LOADING_MYACCOUNT_UPCOMING_WEBINAR_LOADING: 'LOADING_MYACCOUNT_UPCOMING_WEBINAR',
    LOADING_MYACCOUNT_UPCOMING_WEBINAR_COMPLETE: 'LOADING_MYACCOUNT_UPCOMING_WEBINAR_COMPLETE',
    LOADING_MYACCOUNT_RATING_LOADING: 'LOADING_MYACCOUNT_RATING',
    LOADING_MYACCOUNT_RATING_COMPLETE: 'LOADING_MYACCOUNT_RATING_COMPLETE',
    LOADING_USER_INFO: 'LOADING_USER_INFO',
    LOADING_USER_INFO_COMPLETE: 'LOADING_USER_INFO_COMPLETE',
    USER_NOT_LOGGGED_IN: 'USER_NOT_LOGGGED_IN',
    LOADING_MYACCOUNT_ALL_RATING_LOADING:'LOADING_MYACCOUNT_ALL_RATING_LOADING',
    LOADING_MYACCOUNT_ALL_RATING_COMPLETE:'LOADING_MYACCOUNT_ALL_RATING_COMPLETE',
    LOADING_MYACCOUNT_ALL_PAGING_RATING_LOADING:'LOADING_MYACCOUNT_ALL_PAGING_RATING_LOADING',
    LOADING_MYACCOUNT_ALL_PAGING_RATING_COMPLETE:'LOADING_MYACCOUNT_ALL_PAGING_RATING_COMPLETE',
    LOADING_MYACCOUNT_AVARAGE_RATING_LOADING:'LOADING_MYACCOUNT_AVARAGE_RATING_LOADING',
    LOADING_MYACCOUNT_AVARAGE_RATING_COMPLETE:'LOADING_MYACCOUNT_AVARAGE_RATING_COMPLETE',
    LOADING_MYACCOUNT_LIKE_LOADING:'LOADING_MYACCOUNT_LIKE_LOADING',
    LOADING_MYACCOUNT_LIKE_COMPLETE:'LOADING_MYACCOUNT_LIKE_COMPLETE',
    LOADING_USER_DETAILS: 'LOADING_USER_DETAILS',
    LOADING_USER_DETAILS_COMPLETE: 'LOADING_USER_DETAILS_COMPLETE'

}

import React, { useState } from 'react'
import parse from 'html-react-parser'
import LoadingOverlay from 'react-loading-overlay'
import { BarLoader } from 'react-spinners'

const ShowQuestionTest = (cprops) => {
    const [answerId, setAnswerId] = useState(false)

    const selectThisCheck = (answer_id, qobj) => {
        qobj.map((answer) => {
            if (answer.id === answer_id) {
                answer.is_correct = 1

            } else if (answer.is_correct === 1) {
                answer.is_correct = 0
            }
            if (answer.is_correct === 1) {
                setAnswerId(answer.id)
            }
        })

    }
    return (
        <div className="course-test-container">
            <div>
                <span style={{ float: 'left', color: '#000', fontWeight: 'bold' }}>Time left: {cprops.timr}</span>

                <span style={{ float: 'right' }} className="course-test-no"> Question {cprops.currentQuestion + 1} of {cprops.question_data.length}</span>
            </div>
            <div className="clearfix"></div>
            {
                cprops.question_data && cprops.next_question_loading && (
                    <LoadingOverlay
                        active={cprops.next_question_loading}
                        spinner={<BarLoader />}
                    />
                )
            }
            {

                cprops.question_data && !cprops.next_question_loading && (
                    <div>
                        {parse(`${cprops.question_data[cprops.currentQuestion].question}`)}
                        {
                            cprops.question_data[cprops.currentQuestion].glossary_answers.map((answer, index) => (
                                <div className="course-test-option">
                                    <input type="radio" id={answer.id}
                                        onChange={() => selectThisCheck(answer.id, cprops.question_data[cprops.currentQuestion].glossary_answers)}
                                        checked={answer.is_correct === 1}
                                    />
                                    <label for={answer.id}><p>{
                                        parse(`
                                        ${answer.q_option.replace('<p>', '').replace('</p>', '').replace('<br>', '').replace(/\n/g, " ")}
                                        `)
                                    }</p>
                                    </label>
                                </div>
                            ))
                        }
                        <div className="row">
                            <div className="col-4">
                                {
                                    cprops.currentQuestion > 0 &&
                                    <button className="test-btn previous-btn" onClick={() => {
                                        cprops.setCurrentQuestion({ answerid: answerId, questionid: cprops.question_data[cprops.currentQuestion].id, glossaryid: cprops.course_test_id, timeTaken: cprops.timeForEachQuestion, is_next: 0 }, cprops.currentQuestion)
                                        setAnswerId(false)
                                    }}>Prev</button>
                                }
                            </div>
                            <div className="col-4" style={{ textAlign: 'center' }}>
                                {
                                    // (cprops.currentQuestion) === (cprops.question_data.length - 1) &&
                                    <button className="test-btn submit-btn" onClick={() => {
                                        cprops.setCurrentQuestion({ answerid: answerId, questionid: cprops.question_data[cprops.currentQuestion].id, glossaryid: cprops.course_test_id, timeTaken: cprops.timeForEachQuestio, is_next: 2 }, cprops.currentQuestion)
                                        setAnswerId(false)
                                        cprops.onCompleteTest(true)
                                    }}>Submit</button>
                                }
                            </div>
                            <div className="col-4" style={{ textAlign: 'right' }}>{
                                cprops.currentQuestion < (cprops.question_data.length - 1) &&
                                <button className="next-btn test-btn" onClick={() => {
                                    cprops.setCurrentQuestion({ answerid: answerId, questionid: cprops.question_data[cprops.currentQuestion].id, glossaryid: cprops.course_test_id, timeTaken: cprops.timeForEachQuestion, is_next: 1 }, cprops.currentQuestion)
                                    setAnswerId(false)
                                }}>Next</button>

                            }
                            </div>


                        </div>




                    </div>
                )
            }

        </div>

    )
}

export default ShowQuestionTest
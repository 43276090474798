import React from 'react';
import YouTube from 'react-youtube';

export default function YouPlayer(props) {
    const getVideoId = (url) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : false;
    }
    let w = window.screen.availWidth
    let videoWidth = 746
    if(w < 800){
        videoWidth = w 
    }

    const opts = {
        width:videoWidth+"px",
        height:"396px",
        playerVars: {
            autoplay: 1,
        },
    };
    return (
        <YouTube videoId={getVideoId(props.video_link)} opts={opts} onEnd={props.onEnded} />
    )

}
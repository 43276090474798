import React, { useEffect, useState } from 'react'
import { courseDiscussionAction } from '../redux/actions/courseDiscussionAction'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { Link } from "react-router-dom";

export default function SupportForum(props) {

    const dispatch = useDispatch()

    const discussion = useSelector(state => state.coursediscussion)

    const course = useSelector(state => state.course)

    const [isViewReply, setIsViewReply] = useState(false)
    const [replyQuestionId, setReplyQuestionId] = useState(null)

    const [isAskQuestion, setIsAskQuestion] = useState(false)

    useEffect(() => {
        getDiscussions()
    }, [props.course_id])
    const getDiscussions = async () => {
        try {
            await dispatch(courseDiscussionAction.getCourseDiscussion(props.course_id, 1))
        } catch (error) {
           
        }
    }

    const getDateOfTime = (dt) => {
        let mdt = new Date(dt)
        return mdt.getDate() + '-' + (mdt.toLocaleString('default', { month: 'short' })) + '-' + (mdt.getFullYear().toString().substr(-2));
    }

    const getDiscussionNextPage = async (coure_id) => {
        try {
            await dispatch(courseDiscussionAction.getCourseDiscussionNextPage(coure_id, discussion.course_discussion[0].nextpage))
        } catch (error) {
            
        }
    }

    function setReplyData(q_id) {
        setIsViewReply(true)
        setReplyQuestionId(q_id)
    }

    async function postNewQuestion(discussion_title, discussion_description) {
        await dispatch(courseDiscussionAction.postNewDiscussion({ course_id: props.course_id, course_unit_id: course.unit_selected, title: discussion_title, postData: discussion_description }))
        setIsAskQuestion(false)
    }

    async function saveReplyPost(discussion_description, parent_id) {
        await dispatch(courseDiscussionAction.postReplyInDiscussion({ course_id: props.course_id, course_unit_id: course.unit_selected, parent_id: parent_id, postData: discussion_description }))
    }

    const yourVote = async(comment_id, VoteType, parentIndexNo, indexNo)=>{
        try{
            await dispatch(courseDiscussionAction.saveVote({ course_comment_id : comment_id, vote_type: VoteType, index_no: indexNo, parent_index_no: parentIndexNo}))
        } catch(error){

        }
    }

    const ViewReply = (cprops) => {
        const [replyText, setReplyText] = useState()
        const [validated, setValidated] = useState(false)

        function handleDescription(event) {
            setReplyText(event.target.value)
        }
        function saveReply(event) {
            const form = event.currentTarget
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }

            setValidated(true)
            if (form.checkValidity() === true) {
                event.preventDefault()
                event.stopPropagation()
                cprops.saveReplyPost(replyText, replyQ.id)
            }

        }

        const replyQarr = cprops.discussion_question.filter((arr) => { return arr.id === cprops.question_id })
        const replyQ = replyQarr[0]
        const parent_index = cprops.discussion_question.findIndex((x) => x.id === replyQ.id);
        const makeAVote = (id, vote_type, parent_index_no, index_no) =>{
           cprops.yourVote(id, vote_type, parent_index_no, index_no)
          }

        return (
            <section>
                <div className="row">
                    <div className="col-lg-12">
                        <p className="see-more-question-btn mb-0">
                            <span className="pull-left" onClick={() => cprops.setIsViewReply(false)}>Back to all questions</span>
                        </p>
                    </div>
                </div>
                <div className="q-a-section main-question">
                    <div className="user-image-q-and-a">
                        <img alt="" src={replyQ.user.photo} onError={cprops.setErrorImage} className="img-fluid" />
                    </div>
                    <div className="user-question-q-and-a">
                        <span className="q-and-a-question">
                            <p className="question">{replyQ.title}</p>
                            <p className="ans">
                                {replyQ.description}
                            </p>

                        </span>


                    </div>
                    <p className="question-date mb-0">
                        <span style={{ borderRight: '1px solid #d5e7f7' }}>
                            {replyQ.user.first_name} {replyQ.user.last_name}
                        </span>

                        <span className="q-and-a-like">
                            <span style={{ borderRight: '1px solid #d5e7f7', borderLeft: '1px solid #d5e7f7' }}>
                                {cprops.getDateOfTime(replyQ.create_date)}
                            </span>
                            {/** Parent Index use as main index*/}
                            <span style={{ borderRight: '1px solid #d5e7f7' }}>
                                <a onClick={()=>makeAVote(replyQ.id, 1, '', parent_index)} style={{cursor:"pointer"}}><i style={{ fontSize: "12px" }} className="fa fa-thumbs-o-up"  aria-hidden="true"></i> {replyQ.total_up_vote}</a>
                            </span>
                            <span>
                                <a onClick={()=>makeAVote(replyQ.id, 2, '', parent_index)} style={{cursor:"pointer"}}><i style={{ fontSize: "12px" }} className="fa fa-thumbs-o-down" aria-hidden="true"></i> {replyQ.total_down_vote}</a>
                            </span>
                            {/** */}

                        </span>
                    </p>
                </div>
                <p className="mt-4"><b>Add your reply</b></p>
                <Form noValidate validated={validated} onSubmit={saveReply}>
                    <Form.Group controlId="questionForm.question_detail">
                        <Form.Control
                            as="textarea"
                            rows={2}
                            placeholder="Type your reply"
                            required
                            onChange={handleDescription}
                        />
                    </Form.Group>
                    <button type="submit" className="btn my-btn-primary btn-sm mb-4">Post Reply</button>
                </Form>
                <div className="row" style={{ borderBottom: '1px solid #eee' }}>
                    <div className="col-lg-6 text-left">
                        <p>
                            <b>({replyQ.child_course_comments.length}) Replies</b>
                        </p>
                    </div>

                </div>
                {
                    replyQ.child_course_comments.map((c_comment, index) => (
                        <div className="q-a-section">
                            <div className="user-image-q-and-a">
                                <img alt="" src= {c_comment.user.photo!=null ? c_comment.user.photo : 'https://d24uab5gycr2uz.cloudfront.net/uploads/students_pic/user_default.png'} onError={cprops.setErrorImage}  className="img-fluid" />
                            </div>
                            <div className="user-question-q-and-a">
                                <span className="q-and-a-question">
                                    <p className="ans">{c_comment.description}</p>

                                </span>


                            </div>
                            <p className="question-date mb-0 mt-3">
                                <span style={{ borderRight: '1px solid #d5e7f7' }}>
                                    {c_comment.user.first_name} {c_comment.user.last_name}
                                </span>

                                <span className="q-and-a-like">
                                    <span style={{ borderRight: '1px solid #d5e7f7', borderLeft: '1px solid #d5e7f7' }}>
                                        {cprops.getDateOfTime(c_comment.create_date)}
                                    </span>
                                    <span style={{ borderRight: '1px solid #d5e7f7' }}>
                                        <a onClick={()=>makeAVote(c_comment.id, 1, parent_index, index)} style={{cursor:"pointer"}}><i style={{ fontSize: "12px" }} className="fa fa-thumbs-o-up"  aria-hidden="true"></i> {c_comment.total_up_vote}</a>
                                    </span>
                                    <span >
                                        <a onClick={()=>makeAVote(c_comment.id, 2, parent_index, index)} style={{cursor:"pointer"}}><i style={{ fontSize: "12px" }} className="fa fa-thumbs-o-down" aria-hidden="true"></i> {c_comment.total_down_vote}</a>
                                    </span>
                                </span>
                            </p>
                        </div>
                    ))
                }

            </section>
        )
    }

    const AskQuestion = (cprops) => {
        const [validated, setValidated] = useState(false)
        const [question, setQuestion] = useState()
        const [description, setDescription] = useState()

        const saveQuestion = (event) => {
            const form = event.currentTarget
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }

            setValidated(true)
            if (form.checkValidity() === true) {
                event.preventDefault()
                event.stopPropagation()
                cprops.postNewQuestion(question, description)
            }

        }

        const handleQuestion = (event) => {
            setQuestion(event.target.value)
        }
        const handleDescription = (event) => {
            setDescription(event.target.value)
        }

    

        return (
            <div style={{ minHeight: '500px' }}>
                {
                    !cprops.course_discussion_post_question_loading && (
                        <div>
                            <div className="row">
                                <div className="col-lg-12 ">
                                    <p className="see-more-question-btn mb-0"><span className="pull-left" style={{ cursor: 'pointer' }} onClick={() => cprops.setIsAskQuestion(false)}> Back to all question </span></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="tips-for-question">
                                        <p><b>Tips on getting your questions answered faster</b> </p>
                                        <ul>
                                            <li>
                                                Be detailed - error messages, question, or other clues whenever possible.
                                            </li>
                                            <li>
                                                Check grammar and spelling
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Form noValidate validated={validated} onSubmit={saveQuestion} autoComplete={false}>
                                <p><b>Title or summary</b></p>
                                <Form.Group controlId="questionForm.question">
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Question title"
                                        autoComplete={false}
                                        onChange={handleQuestion}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <p><b>Details</b></p>
                                <Form.Group controlId="questionForm.question_detail">
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Short details"
                                        required
                                        onChange={handleDescription}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <button type="submit" className="btn my-btn-primary mt-2 mb-4">Publish</button>
                            </Form>
                        </div>)
                }
                {
                    cprops.course_discussion_post_question_loading && (
                        <span>Posting Question</span>
                    )
                }
            </div>
        )
    }
    const setErrorImage =(e)=>{
        e.target.src = 'https://d24uab5gycr2uz.cloudfront.net/uploads/students_pic/user_default.png'
    }
    return (

        <section className="lms-dashboard-container">

            {
                !discussion.course_discussion_loading && !isViewReply && !isAskQuestion && (
                    <div>
                        <div className="row">
                            {props.callingFrom === 'courseDiscussion' &&
                            <div className=" col-lg-12 mb-3">
                            <span className="support-back-btn pull-right" style={{cursor:"pointer"}} onClick={()=>props.setCurrentCourseId(0)}><i class="fa fa-angle-double-left" aria-hidden="true"></i>Back</span>
                            </div>
                            }
                            <div className="col-lg-12 text-right mb-2">
                                <p><span className="number-of-questions">{discussion.course_discussion[0].total} questions in this course</span> <span style={{ cursor: 'pointer' }} onClick={() => setIsAskQuestion(true)} className="ask-new-question"> Ask a new question </span></p>
                            </div>
                        </div>
                        {
                            discussion.course_discussion[0].data.map((d_unit, index) =>
                                <div key={index} className="q-a-section ">
                                    <div className="user-image-q-and-a">
                                        <img alt="" src={d_unit.user.photo!=null ? d_unit.user.photo : 'https://d24uab5gycr2uz.cloudfront.net/uploads/students_pic/user_default.png'} onError={setErrorImage} className="img-fluid" />
                                    </div>
                                    <div className="user-question-q-and-a">
                                        <span className="q-and-a-question">
                                            <p className="question" style={{ cursor: 'pointer' }} onClick={() => setReplyData(d_unit.id)}>{d_unit.title}</p>
                                            <p className="ans">{d_unit.description}</p>

                                        </span>
                                    </div>
                                    <p className="question-date mb-0">
                                        <span style={{ borderRight: '1px solid #d5e7f7' }}>{d_unit.user.first_name} {d_unit.user.last_name}</span>
                                        {d_unit.child_course_comments.length > 0 ?
                                            <span className="view-reply" onClick={() => setReplyData(d_unit.id)} style={{ borderRight: '1px solid #d5e7f7' }}> View {d_unit.child_course_comments.length} replies</span>
                                            : <span  onClick={() => setReplyData(d_unit.id)} style={{ color: '#ffca98', borderRight: '1px solid #d5e7f7', cursor:'pointer' }}> Add Reply</span>
                                        }
                                        <span className="pull-right p-0">
                                            <span style={{ borderRight: '1px solid #d5e7f7', borderLeft: '1px solid #d5e7f7' }}> {getDateOfTime(d_unit.create_date)}</span>
                                            <span onClick={() => yourVote(d_unit.id, 1, '', index)} style={{ cursor: "pointer", marginRight: "5px", marginLeft: "5px", borderRight: '1px solid #d5e7f7' }}><i style={{ fontSize: "12px" }} className="fa fa-thumbs-o-up" aria-hidden="true"></i> {d_unit.total_up_vote}</span>
                                            <span onClick={() => yourVote(d_unit.id, 2, '', index)} style={{ cursor: "pointer", marginRight: "5px", marginLeft: "5px" }}><i style={{ fontSize: "12px" }} className="fa fa-thumbs-o-down" aria-hidden="true"></i> {d_unit.total_down_vote}</span>
                                        </span>
                                    </p>
                                </div>
                            )
                        }

                        <div className="row">
                            <div className="col-lg-12">
                                <p className="see-more-question-btn mb-0">
                                    {
                                        !discussion.course_discussion_next_loading && (discussion.course_discussion[0].lastpage > discussion.course_discussion[0].nextpage ) && <span onClick={() => getDiscussionNextPage(props.course_id)}>See more</span>
                                    }
                                    {
                                        discussion.course_discussion_next_loading && <span>Loading <i className="fa fa-circle-o-notch fa-spin"></i></span>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                !discussion.course_discussion_loading && isViewReply && !isAskQuestion && (
                    <div>
                        <ViewReply
                            question_id={replyQuestionId}
                            isSaveVoteLoading={discussion.course_discussion_save_vote_loading}
                            discussion_question={discussion.course_discussion[0].data}
                            setIsViewReply={setIsViewReply}
                            yourVote={yourVote}
                            saveReplyPost={saveReplyPost}
                            getDateOfTime={getDateOfTime}
                            setErrorImage={setErrorImage}
                        />
                    </div>
                )
            }
            {
                !discussion.course_discussion_loading && !isViewReply && isAskQuestion && (
                    <div>
                        <AskQuestion
                            postNewQuestion={postNewQuestion}
                            setIsAskQuestion={setIsAskQuestion}
                            course_discussion_post_question_loading={discussion.course_discussion_post_question_loading}
                        />
                    </div>
                )
            }
            {
                discussion.course_discussion_loading && (
                    <div>
                        Loading
                    </div>
                )
            }
        </section>
    )
}
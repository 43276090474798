import React, { useState, useEffect } from 'react'

import './styles/style.css'
import Header from './Element/Header';
import Footer from './Element/Footer';
import LeftPanel from './Element/LeftPanel';
import topbackground from './top-background.png'
import { useSelector, useDispatch } from 'react-redux'
import { myaccountAction } from './redux/actions/myaccountAction'
import MobileVerification from './components/MobileVerification';

const Layout = (props) => {

  const userAccount = useSelector(state => state.myaccount)
  const dispatch = useDispatch(null)

  useEffect(() => {
    getUserData()
    document.title = "Elearnmarkets - " + props.name
  }, [])

  function getUserData() {
    try {
      dispatch(myaccountAction.getUserDetails())
    } catch (error) {
    }
  }

  const [isMobileBarMenuClicked, setIsMobileBarMenuClicked] = useState(false)
  const onMobileMenuClick = (fl) => {
    setIsMobileBarMenuClicked(fl)
  }
  return (

    <section>
      <main role="main" style={{ "background": "#fff", "position": "relative" }}>
        <img alt="" src={topbackground} className="top-background" />
        <Header
          onMobileMenuClick={onMobileMenuClick}
          userInfo={userAccount.user_data}
          userDataLoading={userAccount.user_data_loading}
        />
        <div className="container-fluid padding-on-top-my-account">
          <div className="container">
            <div className="row justify-content-md-center" style={{ minHeight: '500px' }}>

              <LeftPanel
                isMobileBarMenuClicked={isMobileBarMenuClicked}
                onMobileMenuClick={onMobileMenuClick}
                userDataLoading={userAccount.user_data_loading}
                userInfo={userAccount.user_data}
                callFromAuthor={false}
                getUserData={getUserData}
              />
              <div className="col-lg-9">
                <p className="top-header-my-account">
                  {props.nameprefix} <span>{props.name}</span>
                </p>
                <div className="right-containt-section">
                  {props.children}
                </div>
                {
                  !userAccount.user_data_loading &&
                  <MobileVerification />
                }
                <div className='row justify-content-center'>
                  <div className='col-md-11 col-10'>
                    <div className="webinar_plan_offer">
                      <div className="row">
                        <div className="col-md-2 col-12">
                          <span className="webinar_plan_offer_img">
                            <img
                              src="https://d24uab5gycr2uz.cloudfront.net/uploads/white_theme/images/webinar_landing_page/webinar_plan.webp"
                              alt="ELM webinar plan"
                              className="img-fluid"
                            />
                          </span>
                        </div>
                        <div className="col-md-6 col-12 m-auto">
                          <p className="webinar_plan_offer_text">
                            Get more Webinars at a Lesser Price!
                          </p>
                        </div>
                    
                        <div className="col-md-4 col-12 m-auto">
                          <a
                            href="https://www.elearnmarkets.com/webinar-plans"
                            className="webinat_plan_btn"
                            target="_blank"
                          >
                            Explore Webinar Plans
                            <svg
                              width="13"
                              height="11"
                              viewBox="0 0 13 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.3943 5.73713C12.3943 5.92577 12.3223 6.11439 12.1785 6.25822L7.6526 10.7841C7.3647 11.072 6.89791 11.072 6.61013 10.7841C6.32234 10.4963 6.32234 10.0296 6.61013 9.74167L10.6149 5.73713L6.61027 1.73257C6.32248 1.44467 6.32248 0.978024 6.61027 0.69026C6.89805 0.402217 7.36484 0.402217 7.65274 0.69026L12.1786 5.21604C12.3224 5.35994 12.3943 5.54856 12.3943 5.73713Z"
                                fill="#EE9949"
                              ></path>
                              <path
                                d="M6.39429 5.73713C6.39429 5.92577 6.32226 6.11439 6.17851 6.25822L1.6526 10.7841C1.3647 11.072 0.897914 11.072 0.610127 10.7841C0.32234 10.4963 0.32234 10.0296 0.610127 9.74167L4.6149 5.73713L0.610267 1.73257C0.32248 1.44467 0.32248 0.978024 0.610267 0.69026C0.898054 0.402217 1.36484 0.402217 1.65274 0.69026L6.17864 5.21604C6.32242 5.35994 6.39429 5.54856 6.39429 5.73713Z"
                                fill="#EE9949"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>

    </section>
  );
}


export default Layout;

export const authorLmsconstants = {
    LOADING_AUTHOR_LMS_COURSE_DATA: "LOADING_AUTHOR_LMS_COURSE_DATA",
    LOADING_AUTHOR_LMS_COURSE_DATA_SUCCESS: "LOADING_AUTHOR_LMS_COURSE_DATA_SUCCESS",
    AUTHOR_LMS_SECTION_OPEN: "AUTHOR_LMS_SECTION_OPEN",
    AUTHOR_LMS_CHAPTER_OPEN_SUCCESS: "AUTHOR_LMS_CHAPTER_OPEN_SUCCESS",
    LOADING_AUTHOR_LMS_UNIT_DATA: "LOADING_AUTHOR_LMS_UNIT_DATA",
    AUTHOR_LMS_SET_OPEN_DATA: "AUTHOR_LMS_SET_OPEN_DATA",
    LOADING_AUTHOR_LMS_COURSE_TEST_DATA: 'LOADING_AUTHOR_LMS_COURSE_TEST_DATA',
    LOADING_AUTHOR_LMS_COURSE_TEST_DATA_SUCCESS: 'LOADING_AUTHOR_LMS_COURSE_TEST_DATA_SUCCESS',
    AUTHOR_LMS_COURSE_TEST_STARTED: 'AUTHOR_LMS_COURSE_TEST_STARTED',
    AUTHOR_LMS_COURSE_TEST_SAVE_ANSWER_AND_SET_QUESTION: 'AUTHOR_LMS_COURSE_TEST_SAVE_ANSWER_AND_SET_QUESTION',
    AUTHOR_LMS_GOTO_NEXT_UNIT: 'AUTHOR_LMS_GOTO_NEXT_UNIT',
    AUTHOR_LMS_USER_NOT_LOGGED_IN: 'AUTHOR_LMS_USER_NOT_LOGGED_IN',
    AUTHOR_LMS_COURSE_TEST_FINISHED: 'AUTHOR_LMS_COURSE_TEST_FINISHED',
    AUTHOR_LMS_COURSE_TEST_RESULT_GENERATED: 'AUTHOR_LMS_COURSE_TEST_RESULT_GENERATED',
    AUTHOR_LMS_COURSE_TEST_GET_NEXT_QUESTION: 'AUTHOR_LMS_COURSE_TEST_GET_NEXT_QUESTION',
    AUTHOR_LMS_COURSE_TEST_CANCELLED: 'AUTHOR_LMS_COURSE_TEST_CANCELLED',
    AUTHOR_LMS_COURSE_DISCUSSION_LOADING: 'AUTHOR_LMS_COURSE_DISCUSSION_LOADING',
    COURSE_DISCUSSION_LOADING_AUTHOR_LMS_COMPLETE: 'AUTHOR_LMS_COURSE_DISCUSSION_LOADING_COMPLETE',
    AUTHOR_LMS_COURSE_DISCUSSION_NEXT_PAGE_LOADING: 'AUTHOR_LMS_COURSE_DISCUSSION_NEXT_PAGE_LOADING',
    AUTHOR_LMS_COURSE_DISCUSSION_NEXT_PAGE_LOADING_COMPLETE: 'AUTHOR_LMS_COURSE_DISCUSSION_NEXT_PAGE_LOADING_COMPLETE',
    AUTHOR_LMS_COURSE_DISCUSSION_POST_QUESTION_LOADING: 'AUTHOR_LMS_COURSE_DISCUSSION_POST_QUESTION_LOADING',
    AUTHOR_LMS_COURSE_DISCUSSION_POST_QUESTION_SUCCESS: 'AUTHOR_LMS_COURSE_DISCUSSION_POST_QUESTION_SUCCESS',
    AUTHOR_LMS_COURSE_DISCUSSION_POST_REPLY_SUCCESS: 'AUTHOR_LMS_COURSE_DISCUSSION_POST_REPLY_SUCCESS',
    AUTHOR_LMS_COURSE_UNIT_COMPLETE_LOADING: 'AUTHOR_LMS_COURSE_UNIT_COMPLETE_LOADING',
    AUTHOR_LMS_COURSE_UNIT_COMPLETE: 'AUTHOR_LMS_COURSE_UNIT_COMPLETE',
    AUTHOR_LMS_COURSE_BATCH_LOADING: 'AUTHOR_LMS_COURSE_BATCH_LOADING',
    AUTHOR_LMS_COURSE_BATCH_LOADING_COMPLETE: 'AUTHOR_LMS_COURSE_BATCH_LOADING_COMPLETE',
    AUTHOR_LMS_COURSE_BATCH_SESSION_LOADING: 'AUTHOR_LMS_COURSE_BATCH_SESSION_LOADING',
    AUTHOR_LMS_COURSE_BATCH_SESSION_LOADING_COMPLETE: 'AUTHOR_LMS_COURSE_BATCH_SESSION_LOADING_COMPLETE',
    AUTHOR_LMS_COURSE_BATCH_OPEN_SESSION: 'AUTHOR_LMS_COURSE_BATCH_OPEN_SESSION',
    AUTHOR_LMS_COURSE_SESSION_VIDEO_SET: 'AUTHOR_LMS_COURSE_SESSION_VIDEO_SET',
    AUTHOR_LMS_SET_VIDEO_TIME: 'AUTHOR_LMS_SET_VIDEO_TIME'
}

export const actionconstants = {
    LOADING_COURSE_DATA: "LOADING_COURSE_DATA",
    LOADING_COURSE_DATA_SUCCESS: "LOADING_COURSE_DATA_SUCCESS",
    SECTION_OPEN: "SECTION_OPEN",
    CHAPTER_OPEN_SUCCESS: "CHAPTER_OPEN_SUCCESS",
    LOADING_UNIT_DATA: "LOADING_UNIT_DATA",
    SET_OPEN_DATA: "SET_OPEN_DATA",
    LOADING_COURSE_TEST_DATA: 'LOADING_COURSE_TEST_DATA',
    LOADING_COURSE_TEST_DATA_SUCCESS: 'LOADING_COURSE_TEST_DATA_SUCCESS',
    COURSE_TEST_STARTED: 'COURSE_TEST_STARTED',
    COURSE_TEST_SAVE_ANSWER_AND_SET_QUESTION: 'COURSE_TEST_SAVE_ANSWER_AND_SET_QUESTION',
    GOTO_NEXT_UNIT: 'GOTO_NEXT_UNIT',
    USER_NOT_LOGGED_IN: 'USER_NOT_LOGGED_IN',
    COURSE_TEST_FINISHED: 'COURSE_TEST_FINISHED',
    COURSE_TEST_RESULT_GENERATED: 'COURSE_TEST_RESULT_GENERATED',
    COURSE_TEST_GET_NEXT_QUESTION: 'COURSE_TEST_GET_NEXT_QUESTION',
    COURSE_TEST_CANCELLED: 'COURSE_TEST_CANCELLED',
    COURSE_DISCUSSION_LOADING: 'COURSE_DISCUSSION_LOADING',
    COURSE_DISCUSSION_LOADING_COMPLETE: 'COURSE_DISCUSSION_LOADING_COMPLETE',
    COURSE_DISCUSSION_NEXT_PAGE_LOADING: 'COURSE_DISCUSSION_NEXT_PAGE_LOADING',
    COURSE_DISCUSSION_NEXT_PAGE_LOADING_COMPLETE: 'COURSE_DISCUSSION_NEXT_PAGE_LOADING_COMPLETE',
    COURSE_DISCUSSION_POST_QUESTION_LOADING: 'COURSE_DISCUSSION_POST_QUESTION_LOADING',
    COURSE_DISCUSSION_POST_QUESTION_SUCCESS: 'COURSE_DISCUSSION_POST_QUESTION_SUCCESS',
    COURSE_DISCUSSION_SAVE_VOTE_LOADING: 'COURSE_DISCUSSION_SAVE_VOTE_LOADING',
    COURSE_DISCUSSION_SAVE_VOTE_SUCCESS: 'COURSE_DISCUSSION_SAVE_VOTE_SUCCESS',
    COURSE_DISCUSSION_POST_REPLY_SUCCESS: 'COURSE_DISCUSSION_POST_REPLY_SUCCESS',
    COURSE_UNIT_COMPLETE_LOADING: 'COURSE_UNIT_COMPLETE_LOADING',
    COURSE_UNIT_COMPLETE: 'COURSE_UNIT_COMPLETE',
    COURSE_BATCH_LOADING: 'COURSE_BATCH_LOADING',
    COURSE_BATCH_LOADING_COMPLETE: 'COURSE_BATCH_LOADING_COMPLETE',
    COURSE_BATCH_SESSION_LOADING: 'COURSE_BATCH_SESSION_LOADING',
    COURSE_BATCH_SESSION_LOADING_COMPLETE: 'COURSE_BATCH_SESSION_LOADING_COMPLETE',
    COURSE_BATCH_OPEN_SESSION: 'COURSE_BATCH_OPEN_SESSION',
    COURSE_SESSION_VIDEO_SET: 'COURSE_SESSION_VIDEO_SET',
    SET_VIDEO_TIME: 'SET_VIDEO_TIME'
}

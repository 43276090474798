import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-seek-buttons/dist/videojs-seek-buttons.css'

import videoJsContribQualityLevels from 'videojs-contrib-quality-levels'
import videojsHlsQualitySelector from 'videojs-hls-quality-selector'
import seekButtons from 'videojs-seek-buttons'
videojs.registerPlugin('qualityLevel', videoJsContribQualityLevels)
videojs.registerPlugin('hlsQualitySelector', videojsHlsQualitySelector)
videojs.registerPlugin('seekButtons', seekButtons)
const areEqual = (prevProps, nextProps) => {
  return prevProps.src === nextProps.src
};

const usePlayer = ({ src, controls, autoplay, onvideoseeking, onvideoend, onvideoplaying, onvideopause, onvideoresume }) => {
  const options = {
    fill: true,
    fluid: false,
    preload: 'auto',
    responsive: true,
    html5: {
      hls: {
        enableLowInitialPlaylist: true,
        smoothQualityChange: true,
        overrideNative: true,
      },
    },
    plugins: {
      qualityLevel: {},
      hlsQualitySelector: { displayCurrentQuality: true },
      seekButtons: {
        forward: 30,
        back: 10,
      }
    }
  };
  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, {
      ...options,
      controls,
      autoplay,
      playbackRates:[0.5,1,1.25,1.5],
      sources: [src],
    });
    vjsPlayer.on('seeking', () => {
      onvideoseeking()
    })
    vjsPlayer.on('ended', () => {
      onvideoend()
      // setIsEnded(true)
    })
    vjsPlayer.on('playing', ()=>{
      onvideoplaying(vjsPlayer.duration(), vjsPlayer)
    })

    vjsPlayer.on("pause", () => {
      onvideopause();
  });
  vjsPlayer.on("resume", () => {
      onvideoresume(vjsPlayer);
  });

    setPlayer(vjsPlayer);
    return () => {
      if (player !== null) {
        player.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (player !== null) {
      player.src({ src });
      // setIsEnded(false)
    }
  }, [src]);

  return videoRef;
};

const MyVideoJsPlayer = (props) => {
  // const [isEnded, setIsEnded] = useState(false)

  const { src, controls, autoplay, onvideoseeking, onvideoend, onvideoplaying, onvideopause, onvideoresume } = props
  const playerRef = usePlayer({ src, controls, autoplay, onvideoseeking, onvideoend, onvideoplaying, onvideopause, onvideoresume });
  return (
    <div data-vjs-player>
      <video ref={playerRef} className="video-js vjs-fill vjs-layout-medium vjs-big-play-centered" />
      {/* {
        isEnded ? (
          <div className="end-screen-container">
            <p>Video Ended</p>
          </div>
        ) : ''
      } */}
    </div>
  );
};

MyVideoJsPlayer.defaultProps = {
  controls: true,
  autoplay: true,
};


export default React.memo(MyVideoJsPlayer, areEqual);
import { combineReducers } from 'redux'
import courseReducer from './courseReducer'
import accountReducer from './accountReducer'
import courseTestReducer from './courseTestReducer'
import courseDiscussionReducer from './courseDiscussionReducer'
import webinarPlanReducer from './webinarPlanReducer'
import authorLmsReducer from './authorLmsReducer'

const rootReducer = combineReducers(
    {
        course: courseReducer,
        myaccount: accountReducer,
        coursetest: courseTestReducer,
        coursediscussion: courseDiscussionReducer,
        webinarplan: webinarPlanReducer,
        authorLmsData:authorLmsReducer
    }
)

export default rootReducer
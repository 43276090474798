import React, { useEffect, useState } from "react";

import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Webinar from "./Element/Webinar";
import { myaccountAction } from "./redux/actions/myaccountAction";
import { webinarPlanAction } from "./redux/actions/webinarPlanAction";
import Modal from "react-bootstrap/Modal";
import Rating from "./components/Rating";
import emptyWebinar from "./empty-webinar.png";
import ErrorModal from "./Element/ErrorModal";
import Loader from "./Element/Loader";
const MyWebinars = () => {
  const [show, setShow] = useState(false);

  const [currentCourseObj, setCurrentCourseObj] = useState(null);
  const [isError, setIsError] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleOpenRating = (data) => {
    setCurrentCourseObj(data);
    setShow(true);
  };
  const myaccount = useSelector((state) => state.myaccount);
  const dispatch = useDispatch();
  let pageData = myaccount.my_webinar_paging;
  const plansData = useSelector((state) => state.webinarplan);
  const [filterText, setFilterText] = useState("");
  const [clickSearchButton, setClickSearchButton] = useState(false);
  useEffect(() => {
    getWebinarPlan();
    userUpcomingWebinar();
    getUserWebinar(1);
  }, []);
  
  const userUpcomingWebinar = async () => {
    try {
      await dispatch(myaccountAction.getMyUpcomingWebinars());
    } catch (err) {
      setIsError(true);
      throw new Error(err);
    }
  };
  const getWebinarPlan = () => {
    try {
      dispatch(webinarPlanAction.getUserPlansDetails());
    } catch (error) {
      alert("error");
    }
  };
  
  const handleKeyUp = (e) => {
    let val = e.target.value;
    
    setFilterText(val);
  };
  const clearInput = () => {
    setFilterText("");
    
    // Trigger any additional actions when clearing input
  };

  const getUserWebinar = async (currentpage) => {
    try {
      let data = { webinar_name: filterText };
      await dispatch(myaccountAction.getMyWebinars(currentpage, data));
    } catch (err) {
      throw new Error(err);
    }
  };

  const getUserWebinarNextPage = async (currentpage) => {
    try {
      let data = { webinar_name: filterText };
      await dispatch(myaccountAction.getMyWebinarPaging(currentpage, data));
    } catch (err) {
      throw new Error(err);
    }
  };

  const handlePagination = () => {
    if (pageData.nextPage) {
      getUserWebinarNextPage(pageData.page + 1);
    }
  };
  let h = window.screen.availHeight;
     const searchBoxStyles = {
    position: 'relative'
    
  };

  return (
    <Layout nameprefix="My" name="Webinars">
      <div className="row">
        {!plansData.user_webinar_plan_loading &&
          plansData.user_webinar_plan_list.currentsubscription != null && (
            <div className="col-lg-12 mb-4">
              <div className="section-header">
                <p>
                  Plans and <span className="blue-text">Subscription</span>
                </p>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <table className="webinar-plan-table table-responsive">
                    <tr>
                      <th>Plan Name</th>
                      <th>Expiry Date</th>
                      <th>Used</th>
                      <th>Available</th>
                      <th></th>
                    </tr>
                    <tr>
                      <td>
                        <span className="plan-name">
                          <img
                            className="mr-1"
                            src={
                              plansData.user_webinar_plan_list
                                .currentsubscription != null
                                ? plansData.user_webinar_plan_list
                                    .currentsubscription.subscription_plan
                                    .plan_image
                                : ""
                            }
                            style={{ width: "20px" }}
                          />
                          {plansData.user_webinar_plan_list
                            .currentsubscription != null
                            ? plansData.user_webinar_plan_list
                                .currentsubscription.subscription_plan.name
                            : ""}
                        </span>
                      </td>
                      <td>
                        {
                          plansData.user_webinar_plan_list.currentsubscription
                            .expiry_date
                        }
                      </td>
                      <td>
                        {
                          plansData.user_webinar_plan_list.currentsubscription
                            .no_purchased_webinars
                        }
                      </td>
                      <td>
                        {
                          plansData.user_webinar_plan_list.currentsubscription
                            .no_available_webinars
                        }
                      </td>
                      <td className="text-center">
                        {plansData.user_webinar_plan_list.currentsubscription
                          .expiry_diff > -1 && (
                          <Link
                            to={`/webinar-plan/`}
                            className="view-plan-btn"
                            style={{ color: "white" }}
                          >
                            Book Webinar
                          </Link>
                        )}
                        {plansData.user_webinar_plan_list.currentsubscription
                          .is_renew && (
                          <a
                            className="view-plan-btn"
                            style={{
                              background: "#ee9949",
                              color: "#fff",
                              border: "none",
                              fontSize: ".8rem",
                              marginLeft: "5px",
                            }}
                            href={
                              process.env.REACT_APP_NEXT_HOME_URL +
                              "webinar-plans"
                            }
                            target="_blank"
                          >
                            Renew
                          </a>
                        )}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          )}
        {!plansData.user_webinar_plan_loading &&
          plansData.user_webinar_plan_list.currentsubscription == null &&
          (myaccount.my_upcoming_webinar_data.length > 0 ||
            myaccount.my_webinar_data.length > 0) && (
            <div className="col-lg-12 mb-4">
              <div className="section-header">
                <p>
                  Plans and <span className="blue-text">Subscription</span>
                </p>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="when-no-webinar-plan">
                    <p className="mb-0 pt-2">
                      <span>
                        You have not subscribed to any of our plans, yet.{" "}
                      </span>{" "}
                      <a
                        className="view-plan-btn pull-right"
                        href={`${
                          process.env.REACT_APP_NEXT_HOME_URL + "webinar-plans"
                        }`}
                        target="_blank"
                        style={{ color: "white" }}
                      >
                        View Plans
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

        {!myaccount.loading &&
          myaccount.my_upcoming_webinar_data.length > 0 && (
            <div className="col-lg-12 mb-4">
              <div className="section-header">
                <p>
                  upcoming <span className="blue-text">WEBINARS</span>
                </p>
              </div>
              <div className="row">
                {myaccount.my_upcoming_webinar_data.map(function (
                  feedData,
                  index
                ) {
                  return (
                    <Webinar
                      webinarData={feedData}
                      openReviewPopup={handleOpenRating}
                      upcomingWebinar={true}
                    />
                  );
                },
                this)}
              </div>
            </div>
          )}
        {!myaccount.loading &&
          myaccount.my_upcoming_webinar_data.length === 0 &&
          !myaccount.firstwebinarloading &&
          myaccount.my_webinar_data.length === 0 && (
            <div className="col-lg-12 mb-4 text-center">
              <div className="empty-section-top-box" style={{ height: h / 2 }}>
                <div
                  className="empty-section-inner-box"
                  style={{ marginTop: h / 4 - 25 }}
                >
                  <img
                    src={emptyWebinar}
                    alt="logo"
                    className="img-fluid empty-page-img"
                  />
                  <p className="empty-page-text">
                    Join and learn from webinars by popular market experts.
                  </p>
                  <a
                    href={`${process.env.REACT_APP_NEXT_HOME_URL + "webinars"}`}
                    target="_blank"
                    style={{ color: "white" }}
                    className="elm-primary-btn"
                  >
                    View Webinars
                  </a>
                </div>
              </div>
            </div>
          )}
  {!myaccount.firstwebinarloading && (clickSearchButton ||
            myaccount.my_webinar_data.length > 0) && (
        <div className="col-lg-12 mb-4">
          <div className="section-header">
            <p>
              PAST <span className="blue-text">WEBINARS</span>
            </p>
          </div>
          {!myaccount.firstwebinarloading && (
            <div className="row  mb-2">
              <div className="col-lg-6">
                <div className="checkbox custom-checkbox-elm mb-3" style={searchBoxStyles}>
                  <input
                    type="text"
                    name="search_webinar_title"
                    placeholder="Search by webinar name or author name"
                    onChange={handleKeyUp}

                    value={filterText}
                    id="filtertext"
                    
                    className="form-control"
                    style={{paddingRight:'30px'}}
                  />
                  {filterText /* Show clear button only when there's input */ && (
                    <button type="button"
                      onClick={clearInput}
                      style={{
                        background: 'transparent',
                        border: 'none',
                        position: 'absolute',
                        top: '4px',
                        right: '4px'
                      }}
                    >
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_52_2103)"><path d="M0.488373 10.0001C0.391786 10.0001 0.297365 9.97146 0.21705 9.9178C0.136736 9.86415 0.0741379 9.78789 0.0371735 9.69865C0.000209163 9.60942 -0.00946059 9.51123 0.00938739 9.4165C0.0282354 9.32177 0.0747543 9.23476 0.14306 9.16647L9.1665 0.143033C9.25808 0.0514506 9.38229 0 9.51181 0C9.64133 0 9.76554 0.0514506 9.85712 0.143033C9.9487 0.234616 10.0002 0.358828 10.0002 0.488346C10.0002 0.617863 9.9487 0.742076 9.85712 0.833658L0.833685 9.8571C0.78838 9.90249 0.734551 9.9385 0.675292 9.96303C0.616033 9.98757 0.55251 10.0002 0.488373 10.0001Z" fill="#547580"></path><path d="M9.51178 10.0001C9.44765 10.0002 9.38412 9.98757 9.32486 9.96303C9.2656 9.9385 9.21178 9.90249 9.16647 9.8571L0.143033 0.833658C0.0514506 0.742076 0 0.617863 0 0.488346C0 0.358828 0.0514506 0.234616 0.143033 0.143033C0.234616 0.0514506 0.358828 0 0.488346 0C0.617863 0 0.742076 0.0514506 0.833658 0.143033L9.8571 9.16647C9.9254 9.23476 9.97192 9.32177 9.99077 9.4165C10.0096 9.51123 9.99995 9.60942 9.96298 9.69865C9.92602 9.78789 9.86342 9.86415 9.7831 9.9178C9.70279 9.97146 9.60837 10.0001 9.51178 10.0001Z" fill="#547580"></path></g><defs><clipPath id="clip0_52_2103"><rect width="10" height="10" fill="white"></rect></clipPath></defs></svg>
                      {" "}
                      {/* This is the "X" symbol for the clear button */}
                    </button>
                  )}
                </div>
              </div>
              <div className="col-lg-6 pl-lg-0 text-lg-left text-center">
                <button
                  className="btn elm-btn elm-btn-primary elm-small-btn pr-2"
                  onClick={(e) => {
                    setClickSearchButton(true);
                    getUserWebinar(1);
                  }}
                >
                  <i className="fa fa-search mr-1 ml-1" aria-hidden="true" />{" "}
                  Search
                </button>
              </div>
            </div>
          )}
          {!myaccount.firstwebinarloading &&
            myaccount.my_webinar_data.length > 0 && (
              <div className="row">
                {myaccount.my_webinar_data.map(function (feedData, index) {
                  return (
                    <Webinar
                      webinarData={feedData}
                      openReviewPopup={handleOpenRating}
                      upcomingWebinar={false}
                    />
                  );
                }, this)}
                {pageData.nextPage && (
                  <div className="col-lg-12">
                    {!myaccount.pageloading ? (
                      <p className="text-right">
                        <button
                          className="show-more-btn show-more-btn-center"
                          onClick={handlePagination}
                        >
                          more{" "}
                          <i
                            className="fa fa-angle-double-down"
                            aria-hidden="true"
                          />
                        </button>
                      </p>
                    ) : (
                      <p className="text-right">
                        <button className="show-more-btn show-more-btn-center">
                          Loading{" "}
                          <i
                            className="fa fa-circle-o-notch fa-spin"
                            aria-hidden="true"
                          />
                        </button>
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
          {!myaccount.firstwebinarloading &&
            myaccount.my_webinar_data.length === 0 && (
              <div className="col-lg-12 text-center">
                <p className="mt-4">
                  Sorry, no webinars were found at the moment. 
                  
                </p>
              </div>
            )}
        </div>
            )}
        <Modal
          show={show}
          onHide={handleClose}
          animation={false}
          className="custom-modal"
        >
          <Modal.Header closeButton>
            {currentCourseObj && (
              <Modal.Title>{currentCourseObj.name}</Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            {currentCourseObj && (
              <Rating
                item_id={currentCourseObj.id}
                modalClosed={handleClose}
                item_type={4}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
      {(myaccount.firstwebinarloading || myaccount.loading) && !isError && (
        <Loader
          is_loading={
            (myaccount.firstwebinarloading || myaccount.loading) && !isError
          }
        />
      )}
      {isError && <ErrorModal error={isError} />}
    </Layout>
  );
};

export default MyWebinars;

import React, { useState, useLayoutEffect } from "react";
import CourseContent from "./CourseContent";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Section from "./Section";
import { useDispatch, useSelector } from "react-redux";
import { courseAction } from "../redux/actions/courseAction";
import { courseTestAction } from "../redux/actions/courseTestAction";
import LoadingOverlay from "react-loading-overlay";
import MoonLoader from "react-spinners/MoonLoader";
import logo from "../new_elm_logo.png";
import smallLogo from "../small-elm-logo.png";

import right from "../right.png";
import left from "../left.png";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { siteConstants } from "../constants";
import SupportForum from "./SupportForum";
import TestSectionRighrBar from "../Element/TestSectionRightBar";
import TestSectionRightResultBar from "../Element/TestSectionRightResultBar";
import Notes from "../Element/Notes";
import LiveClassRightBar from "../Element/LiveClassRightBar";
import LiveSessionVideo from "../Element/LiveSessionVideo";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Rating from "./Rating";
import { fetchDataWithCsrf } from "../services/fetchDataWithCsrf";
import parse from "html-react-parser";

let intv;
let time_for_each_question;
let transcribe_timer;

export default function MainComponent({ course_id_from_params }) {
  const [lpanelopen, setLpanelopen] = useState("");

  let course_id = course_id_from_params;

  const dispatch = useDispatch();

  const course = useSelector((state) => state.course);

  const coursetest = useSelector((state) => state.coursetest);

  const [showCourseContentRight, setshowCourseContentRight] = useState(true);
  const [userRatingData, setUserRatingData] = useState();
  const [show, setShow] = useState(false);
  const [bottomSectionShow, setBottomSectionShow] = useState(1);
  const [transcribeText, setTranscribeText] = useState();
  const [loadCourseData, setloadCourseData] = useState(false);

  const handleClose = () => {
    getUserRating();
    setShow(false);
  };
  useLayoutEffect(() => {
    //getUserRating();
    getCourseInfo();
  }, []);
  const getUserRating = async () => {
    let d = await fetchDataWithCsrf(
      { item_id: course_id, item_type: 1 },
      "ajax/getUserRating"
    );
    setUserRatingData(d.data);
  };
  const getCourseInfo = async () => {
    try {
      setloadCourseData(false);
      await dispatch(courseAction.getInitialDataOnLoad(course_id));
      setloadCourseData(true);
    } catch (err) {
      window.location.href ="/my-courses";
      throw new Error("e");
    }
  };

  const setOpenSection = (index) => {
    dispatch(courseAction.openSection(index));
  };

  const setOpenChapter = async (
    section_index,
    chapter_id,
    chapter_index,
    is_unit_loaded
  ) => {
    try {
      await dispatch(
        courseAction.openChapter(
          section_index,
          chapter_id,
          chapter_index,
          is_unit_loaded
        )
      );
    } catch (err) {
      throw new Error("err");
    }
  };

  const setSelectionData = (
    sec_id,
    chap_id,
    unit_id,
    chapter_to_be_shown = false
  ) => {
    try {
      dispatch(
        courseAction.setSelectedData(
          sec_id,
          chap_id,
          unit_id,
          chapter_to_be_shown,
          course_id
        )
      );
      setMobilePanelClass("");
      setIsShowLiveClass(false);
      setshowCourseContentRight(true);
      setBottomSectionShow(1);
      setTranscribeText(null);
      clearInterval(transcribe_timer);
    } catch (err) {
      throw new Error(err);
    }
  };

  const getbloghtml = async (blog_url) => {
    try {
      let uri =
        "https://www.elearnmarkets.com/blog/wp-json/wp/v2/posts?slug=" +
        blog_url
          .replace("https://www.elearnmarkets.com/blog/", "")
          .replace("/", "");
      let r = await fetch(uri).then((resp) => {
        return resp.json();
      });
      let r2 = await r[0].content.rendered;
      return r2;
    } catch (err) {
      throw new Error(err);
    }
  };

  const setCourseTestData = async (gl_id, unit_selected) => {
    try {
      await dispatch(
        courseTestAction.gettestdetail(gl_id, course_id, unit_selected)
      );
    } catch (err) {
      throw new Error(err);
    }
  };

  const setCourseTestStarted = () => {
    try {
      dispatch(courseTestAction.setTestStarted());
      setLpanelopen("");
      startTimerForEachQuestion();
      setshowCourseContentRight(false);
    } catch (err) {
      throw new Error(err);
    }
  };

  const saveAnswerandSetCurrentQuestion = async (
    answerObj,
    current_selected_question_index
  ) => {
    try {
      await dispatch(
        courseTestAction.answerSaveAnswerandSetCurrentQuestionlected(
          answerObj,
          current_selected_question_index
        )
      );
      clearInterval(time_for_each_question);
      startTimerForEachQuestion();
    } catch (err) {
      throw new Error(err);
    }
  };
  const gotoNextUnit = async (is_next) => {
    if (course.course_initial_data_loading === true) {
      return;
    }
    setBottomSectionShow(1);
    setTranscribeText(null);
    clearInterval(transcribe_timer);
    let { section_selected, chapter_selected, unit_selected } = JSON.parse(
      localStorage.getItem("current_object")
    );
    let c_sec1 = [...course.course_data];

    let selected_section_data = c_sec1.filter((arr) => {
      return parseInt(arr.id) === parseInt(section_selected);
    });
    let selected_chapter_data = selected_section_data[0].course_chapters.filter(
      (arr) => {
        return parseInt(arr.id) === parseInt(chapter_selected);
      }
    );
    let selected_unit_data = selected_chapter_data[0].unit.filter((arr) => {
      return parseInt(arr.id) === parseInt(unit_selected);
    });

    let total_chapter_in_section =
      selected_section_data[0].course_chapters.length;
    let last_or_first_chapter_id = null;
    let total_unit_in_chapter = selected_chapter_data[0].unit.length;
    let new_unit_selected_id = null;
    let new_unit_selected_index = null;
    let new_chapter_selected_index = null;
    let last_or_first_unit = null;
    let new_section_index = null;

    let new_selected_chapter = [];

    if (is_next === 1) {
      last_or_first_unit =
        selected_chapter_data[0].unit[total_unit_in_chapter - 1];
      last_or_first_chapter_id =
        selected_section_data[0].course_chapters[total_chapter_in_section - 1]
          .id;
    } else if (is_next === 0) {
      last_or_first_unit = selected_chapter_data[0].unit[0];
      last_or_first_chapter_id = selected_section_data[0].course_chapters[0].id;
    }

    if (
      parseInt(selected_unit_data[0].id) !== parseInt(last_or_first_unit.id)
    ) {
      //not the last unit so point to next unit
      selected_chapter_data[0].unit.map((u, i) => {
        if (u.id === unit_selected) {
          if (is_next === 1) {
            new_unit_selected_index = i + 1;
          } else {
            new_unit_selected_index = i - 1;
          }
        }
      });
      new_unit_selected_id =
        selected_chapter_data[0].unit[new_unit_selected_index].id;
      setSelectionData(
        section_selected,
        chapter_selected,
        new_unit_selected_id
      );
    } else {
      if (chapter_selected !== last_or_first_chapter_id) {
        //change the chapter and also set the first unit as opened
        selected_section_data[0].course_chapters.map((c, i) => {
          if (c.id === chapter_selected) {
            if (is_next === 1) {
              new_chapter_selected_index = i + 1;
            } else {
              new_chapter_selected_index = i - 1;
            }
          }
        });
        new_selected_chapter[0] =
          selected_section_data[0].course_chapters[new_chapter_selected_index];

        c_sec1.map((sec, i) => {
          if (parseInt(sec.id) === parseInt(section_selected)) {
            new_section_index = i;
          }
        });
        if (new_selected_chapter[0].is_unit_loaded) {
          new_selected_chapter[0].showUnit = true;
          if (is_next === 1) {
            new_unit_selected_id = new_selected_chapter[0].unit[0].id;
          } else {
            new_unit_selected_id =
              new_selected_chapter[0].unit[
                new_selected_chapter[0].unit.length - 1
              ].id;
          }

          await setOpenChapter(
            new_section_index,
            new_selected_chapter[0].id,
            new_chapter_selected_index,
            true
          );
          setSelectionData(
            section_selected,
            new_selected_chapter[0].id,
            new_unit_selected_id,
            true
          );
        } else {
          await gotoNextChapter(
            new_section_index,
            new_selected_chapter[0].id,
            new_chapter_selected_index
          );
        }
      } else {
        //end of chapter and section - goto next section
        let current_section_index = null;
        c_sec1.map((sec, i) => {
          if (parseInt(sec.id) === parseInt(section_selected)) {
            current_section_index = i;
          }
        });
        if (is_next === 0 && current_section_index === 0) {
          //at start
          alert("You are at start");
        } else {
          if (is_next === 0) {
            new_section_index = current_section_index - 1;
            //setOpenSection()
          } else {
            new_section_index = current_section_index + 1;
          }
          setOpenSection(new_section_index);
          await setOpenChapter(
            new_section_index,
            c_sec1[new_section_index].course_chapters[0].id,
            0,
            false
          );
          let new_course = [...course.course_data];
          setSelectionData(
            new_course[new_section_index].id,
            new_course[new_section_index].course_chapters[0].id,
            new_course[new_section_index].course_chapters[0].unit[0].id,
            true
          );
        }
      }
    }
  };
  /* if next chapter data is not loaded */
  async function gotoNextChapter(new_section_index, chapter_id, chapter_index) {
    await setOpenChapter(new_section_index, chapter_id, chapter_index, false);
    gotoNextUnit(1);
  }

  const onvideoseeking = () => {
    console.log("seeking main");
  };

  const onvideoend = () => {
    let { unit_selected, section_selected, chapter_selected } = JSON.parse(
      localStorage.getItem("current_object")
    );
    unitComplete(section_selected, chapter_selected, unit_selected);
    gotoNextUnit(1);
    clearInterval(transcribe_timer);
  };

  const onvideopause = () => {
    clearInterval(transcribe_timer);
  };

  const onvideoresume = (Player) => {
    startTimer(Player);
  };

  const onvideoplaying = (duration, Player) => {
    let { section_selected, chapter_selected, unit_selected } = JSON.parse(
      localStorage.getItem("current_object")
    );
    let c_sec1 = [...course.course_data];

    let selected_section_data = c_sec1.filter((arr) => {
      return parseInt(arr.id) === parseInt(section_selected);
    });
    let selected_chapter_data = selected_section_data[0].course_chapters.filter(
      (arr) => {
        return parseInt(arr.id) === parseInt(chapter_selected);
      }
    );
    let selected_unit_data = selected_chapter_data[0].unit.filter((arr) => {
      return parseInt(arr.id) === parseInt(unit_selected);
    });

    startTimer(Player);
    dispatch(
      courseAction.setVideoTime(
        duration,
        selected_unit_data[0].filecontent.id,
        section_selected,
        chapter_selected,
        unit_selected
      )
    );
  };

  const unitComplete = (sec_id, chap_id, unit_id) => {
    try {
      dispatch(courseAction.completeUnit(sec_id, chap_id, unit_id, course_id));
    } catch (err) {
      alert("Error" + err);
    }
  };

  const gotoLogin = () => {
    window.location.href = process.env.REACT_APP_NEXT_HOME_URL + "login";
  };

  const [showTestConfirmModel, setShowTestConfirmModel] = useState(false);
  const [timeR, setTimeR] = useState("00:00:00");
  const [timeT, setTimeT] = useState(0);
  const [totalTimeTaken, setTotalTimeTaken] = useState(0);
  const [updateloadvideotime, setUpdateloadvideotime] = useState(false);
  const [lastviewvideotime, setLastviewvideotime] = useState(0);

  function startClock(total_time) {
    let i = 0;
    intv = setInterval(() => {
      i++;
      const ct = getRemainToHHMMSS(total_time * 60 - i);
      setTimeR(ct);
      setTimeT(i);
    }, 1000);
  }
  const getRemainToHHMMSS = (remaining) => {
    let secData = remaining;
    let resultText;
    let hours =
      Math.floor(secData / 3600) < 10
        ? ("00" + Math.floor(secData / 3600)).slice(-2)
        : Math.floor(secData / 3600);
    let minutes = ("00" + Math.floor((secData % (60 * 60)) / 60)).slice(-2);
    let seconds = ("00" + ((secData % 3600) % 60)).slice(-2);
    if (secData <= 0) {
      alert("Times up");
      finishTest();
    }
    if(Number(hours) > 0) {
      resultText = hours + ":" + minutes + ":" + seconds;
    } else {
      resultText = minutes + ":" + seconds;
    }
    return resultText;
    
  };

  const finishTest = async () => {
    clearInterval(intv);
    clearInterval(time_for_each_question);
    setTimeT(0);
    setTimeR("00:00:00");
    setShowTestConfirmModel(false);
    try {
      let { section_selected, chapter_selected, unit_selected } = course;
      let all_test_question1 = [...coursetest.course_test_data];
      let reqObj = {
        course_id: course_id,
        currentObj: {
          section_id: section_selected,
          chapter_id: chapter_selected,
          unit_id: unit_selected,
        },
        data: all_test_question1[0],
        timeTaken: timeT,
      };
      setTotalTimeTaken(timeT);
      await dispatch(courseTestAction.submitTest(reqObj));
      unitComplete(section_selected, chapter_selected, unit_selected);
    } catch (err) {
      alert("error occurred generating result");
    }
  };

  const [isCancellTest, setIsCancellTest] = useState(false);
  const cancelTest = () => {
    clearInterval(intv);
    clearInterval(time_for_each_question);
    setTimeT(0);
    setTimeR("00:00:00");
    dispatch(courseTestAction.cancelTest());
    setLpanelopen("");
    setIsCancellTest(false);
    gotoNextUnit(1);
  };

  const [timeForEachQuestion, setTimeForEachQuestion] = useState(0);
  const startTimerForEachQuestion = () => {
    let t = 0;
    time_for_each_question = setInterval(() => {
      t++;
      setTimeForEachQuestion(t);
    }, 1000);
  };

  const [mobilePanelClass, setMobilePanelClass] = useState("");

  const openMobileSideMenu = () => {
    setLpanelopen("");
    setMobilePanelClass("open-for-mobile-right-menu");
  };

  const [isShowLiveClass, setIsShowLiveClass] = useState(false);

  const showLiveClass = () => {
    setIsShowLiveClass(true);
    setshowCourseContentRight(false);
    setLpanelopen("");
    dispatch(courseAction.loadBatches(course_id));
  };

  const showCourseContentRightBar = () => {
    setIsShowLiveClass(false);
    setshowCourseContentRight(true);
  };

  const getBatchSessions = (batch_id) => {
    dispatch(courseAction.getBatchSessions(batch_id, course_id));
  };

  const openSession = (batch_index, session_index) => {
    dispatch(courseAction.openSession(batch_index, session_index));
  };
  const [lastSessionId, setLastSessionId] = useState(0);
  const setSessionVideo = (data) => {
    if (lastSessionId === data.id) {
      return false;
    }
    setUpdateloadvideotime(false);
    setLastSessionId(data.id);
    setMobilePanelClass("");
    setIsShowLiveClass(true);
    setshowCourseContentRight(false);
    dispatch(courseAction.setSessionVideo(data));
  };

  const markAsLearn = () => {
    if (
      course.unit_data.file_type_id !== 2 &&
      course.unit_data.file_type_id !== 13 &&
      course.unit_data.file_type_id !== 7
    ) {
      unitComplete(
        course.section_selected,
        course.chapter_selected,
        course.unit_selected
      );
    }
  };
  const handleUpdateloadvideotime = (v) => {
    setUpdateloadvideotime(v);
  };
  function genCaptionInfo(object_items) {
    let caption_txt = "";
    let myclass = "";
    let space;
    let line_break;
    let line_break_pos_arr = [];
    let is_break = false;
    let para;
    object_items.map((item, i) => {
      space = "&nbsp;";
      myclass = item.className;
      line_break = "";
      if (item.type === "punctuation") {
        myclass = item.className;
        space = "";
        if (item.alternatives[0].content === ".") {
          line_break = "<br />";

          space = "";
          line_break_pos_arr.push(i);
        }
      }
      if (i === 0) {
        space = "";
        para =
          '<p style="line-height: 28px;color: #404040 !important;word-break: break-all;display: block;">';
      }
      is_break = line_break_pos_arr.find((v) => {
        return v === i - 1;
      });
      if (is_break) {
        space = " ";
      }
      caption_txt +=
        para +
        '<span style="display: inline-block;"><span start_time="' +
        item.start_time +
        '" index="' +
        i +
        '" class="' +
        myclass +
        '">' +
        space +
        item.alternatives[0].content +
        "</span></span>";

      para = "";
      if (item.alternatives[0].content === ".") {
        caption_txt +=
          '</p><p style="line-height: 28px;color: #404040 !important;word-break: break-all;display: block;">';
      }
    });

    return caption_txt;
  }

  let unitData;
  function startTimer(player) {
    let { section_selected, chapter_selected, unit_selected } = JSON.parse(
      localStorage.getItem("current_object")
    );
    let c_sec1 = [...course.course_data];

    let selected_section_data = c_sec1.filter((arr) => {
      return parseInt(arr.id) === parseInt(section_selected);
    });
    let selected_chapter_data = selected_section_data[0].course_chapters.filter(
      (arr) => {
        return parseInt(arr.id) === parseInt(chapter_selected);
      }
    );
    let selected_unit_data = selected_chapter_data[0].unit.filter((arr) => {
      return parseInt(arr.id) === parseInt(unit_selected);
    });

    unitData = selected_unit_data[0].filecontent;

    if (parseInt(unitData.transcribe_status) === 2 && unitData.show_on_site) {
      setBottomSectionShow(3);
      transcribe_timer = setInterval(function () {
        let t = player.currentTime();
        let to_json_data = JSON.parse(unitData.transcribe_text);
        let json_data = to_json_data.results.items;

        let v = json_data.findIndex(
          (arr) => parseFloat(arr.start_time) >= parseFloat(t.toFixed(2))
        );

        json_data[v].className = "transcript_active";

        let caption = genCaptionInfo(json_data);
        setTranscribeText(caption);
      }, 100);
    } else {
      setBottomSectionShow(1);
      setTranscribeText(null);
    }
  }

  if (course && course.course_initial_data_loading) {
    return (
      <main role="main">
        <div className="container-fluid" style={{ height: "300px" }}>
          <div style={{ marginTop: "230px" }}>
            <LoadingOverlay
              active={course.course_initial_data_loading}
              spinner={<MoonLoader color="#ee9949" />}
            />
          </div>
        </div>
      </main>
    );
  }
  if (course && course.user_not_logged_in) {
    return (
      <Modal show={course.user_not_logged_in}>
        <Modal.Header closeButton={false}>
          <Modal.Title>Session Expired </Modal.Title>
        </Modal.Header>
        <Modal.Body>Please log in again</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => gotoLogin()}>
            Goto Login
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <main role="main">
        <div
          className="container-fluid pl-0 pr-0 overflow-hidden"
          style={{ background: "#fff" }}
        >
          <div className="top-header-style pl-1 pr-1 header-for-pc">
            <div className="row">
              <div className="col-lg-2 col-2 text-center">
                <a
                  href={process.env.REACT_APP_NEXT_HOME_URL}
                  target="_blank"
                  style={{ display: "block" }}
                >
                  <img
                    src={logo}
                    alt="logo"
                    className="img-fluid lms-elm-icon"
                  />
                </a>
              </div>
              <div
                className="col-lg-6 col-10"
                style={{ boxShadow: "inset 4px 0px 8px #f3f3f3" }}
              >
                <p className="course-name-top-header">{course.course_name}</p>
              </div>
              <div className="col-lg-4 col-10 pl-0">
                {parseInt(
                  course.course_completion_percentage_info[0].totalPercentage
                ) > 70 &&
                  userRatingData <= 0 && (
                    <span>
                      <Link
                        className="rate-this-course-text"
                        onClick={() => setShow(true)}
                      >
                        Rate this course
                      </Link>
                    </span>
                  )}
                <span className="percentage-of-complete-in-header">
                  {course.course_completion_percentage_info[0].totalPercentage >
                  100
                    ? 100
                    : course.course_completion_percentage_info[0]
                        .totalPercentage}
                  %
                </span>

                {parseInt(
                  course.course_completion_percentage_info[0].totalPercentage
                ) <= 0 && (
                  <img
                    src={require("../complete_images/0.svg")}
                    alt="complete"
                    className="img-fluid"
                  />
                )}
                {parseInt(
                  course.course_completion_percentage_info[0].totalPercentage
                ) > 0 &&
                  parseInt(
                    course.course_completion_percentage_info[0].totalPercentage
                  ) < 100 && (
                    <img
                      src={require("../complete_images/" +
                        (parseInt(
                          course.course_completion_percentage_info[0]
                            .totalPercentage / 20
                        ) *
                          20 +
                          20) +
                        ".svg")}
                      alt="complete"
                      className="img-fluid"
                    />
                  )}
                {parseInt(
                  course.course_completion_percentage_info[0].totalPercentage
                ) >= 100 && (
                  <img src={require("../complete_images/100.svg")} alt="" />
                )}

                <NavLink to="/" className="go-to-my-account">
                  Exit<i class="fa fa-power-off ml-1" aria-hidden="true"></i>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="header-for-mobile">
            <div className="row">
              <div className="col-4 text-left">
                <a
                  href="#"
                  onClick={() => openMobileSideMenu()}
                  className="right-sideopen-btn"
                >
                  <i class="fa fa-bars" aria-hidden="true"></i>
                </a>
                <a href={process.env.REACT_APP_NEXT_HOME_URL} style={{ display: "block" }}>
                  <img
                    src={smallLogo}
                    alt="logo"
                    className="img-fluid logo-for-mobile"
                  />
                </a>
              </div>
              <div className="col-8 pl-0">
                <span className="percentage-of-complete-in-header">
                  {course.course_completion_percentage_info[0]
                    .totalPercentage >= 100
                    ? 100
                    : course.course_completion_percentage_info[0]
                        .totalPercentage}
                  %
                </span>
                {parseInt(
                  course.course_completion_percentage_info[0].totalPercentage
                ) <= 0 && (
                  <img
                    src={require("../complete_images/0.svg")}
                    alt="complete"
                    className="img-fluid percentage-of-complete-in-header-img"
                  />
                )}
                {parseInt(
                  course.course_completion_percentage_info[0].totalPercentage
                ) > 0 &&
                  parseInt(
                    course.course_completion_percentage_info[0].totalPercentage
                  ) < 100 && (
                    <img
                      src={require("../complete_images/" +
                        (parseInt(
                          course.course_completion_percentage_info[0]
                            .totalPercentage / 20
                        ) *
                          20 +
                          20) +
                        ".svg")}
                      alt="complete"
                      className="img-fluid percentage-of-complete-in-header-img"
                    />
                  )}
                {parseInt(
                  course.course_completion_percentage_info[0].totalPercentage
                ) >= 100 && (
                  <img
                    src={require("../complete_images/100.svg")}
                    alt=""
                    className="img-fluid percentage-of-complete-in-header-img"
                  />
                )}
                <NavLink to="/" className="go-to-my-account">
                  <i class="fa fa-power-off" aria-hidden="true"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <Router>
          <div className={`main-container-box ${lpanelopen}`}>
            <div className="left-section-for-elearn">
              <div className="e-learning-content-section">
                {parseInt(
                  course.course_completion_percentage_info[0].totalPercentage
                ) > 70 &&
                  userRatingData <= 0 && (
                    <p className="course-rating-box">
                      <Link
                        className="rate-this-course-text"
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        Rate this course
                      </Link>
                    </p>
                  )}
                <button
                  className="show-side-menu-lms-btn"
                  onClick={() => setLpanelopen("")}
                >
                  <i
                    className="fa fa-long-arrow-left mr-2"
                    aria-hidden="true"
                  ></i>
                  Course content
                </button>
                {showCourseContentRight && (
                  <span>
                    <button
                      className="previous-button"
                      onClick={() => gotoNextUnit(0)}
                    >
                      <img
                        src={left}
                        alt="left"
                        className="next-previous-icon"
                      />
                    </button>
                    <button
                      className="next-button"
                      onClick={() => gotoNextUnit(1)}
                    >
                      <img
                        src={right}
                        alt="right"
                        className="next-previous-icon"
                      />
                    </button>
                  </span>
                )}
                {course.has_batch && (
                  <button
                    className="show-side-menu-lms-btn"
                    style={{ top: "100px" }}
                    onClick={() => showLiveClass()}
                  >
                    <i
                      className="fa fa-long-arrow-left mr-2"
                      aria-hidden="true"
                    ></i>
                    Live Classes
                  </button>
                )}
                <div className="row  justify-content-md-center">
                  <div className="col-lg-12">
                    <Switch>
                      <Route path={`/learn/${course_id}`}>
                        {course &&
                          !course.course_initial_data_loading &&
                          !isShowLiveClass &&
                          loadCourseData && (
                            <div>
                              <Section
                                unit_data={course.unit_data}
                                getbloghtml={getbloghtml}
                                gettestdetail={setCourseTestData}
                                unit_selected={course.unit_selected}
                                course_test_info={coursetest.course_test_data}
                                course_test_data_success_value={
                                  coursetest.course_test_data_success_value
                                }
                                course_test_all_data={
                                  coursetest.course_test_all_data
                                }
                                course_test_loading={
                                  coursetest.course_test_loading
                                }
                                startCourseTest={setCourseTestStarted}
                                saveAnswerandSetCurrentQuestion={
                                  saveAnswerandSetCurrentQuestion
                                }
                                isCourseTestStarted={
                                  coursetest.course_test_started
                                }
                                current_question_no={
                                  coursetest.current_question_no
                                }
                                onvideoseeking={onvideoseeking}
                                onvideoend={onvideoend}
                                onvideoplaying={onvideoplaying}
                                onvideopause={onvideopause}
                                onvideoresume={onvideoresume}
                                section_selected={course.section_selected}
                                chapter_selected={course.chapter_selected}
                                showTestConfirmModel={showTestConfirmModel}
                                setShowTestConfirmModel={
                                  setShowTestConfirmModel
                                }
                                startClock={startClock}
                                timeR={timeR}
                                finishTest={finishTest}
                                course_test_submit={
                                  coursetest.course_test_submit
                                }
                                course_test_result_loading={
                                  coursetest.course_test_result_loading
                                }
                                course_test_result={
                                  coursetest.course_test_result
                                }
                                next_question_loading={
                                  coursetest.next_question_loading
                                }
                                timeForEachQuestion={timeForEachQuestion}
                                course_completion_percentage_info={
                                  course.course_completion_percentage_info
                                }
                                selected_course_unit={
                                  course.selected_course_unit
                                }
                                unitcomplete={unitComplete}
                                markAsLearn={markAsLearn}
                              />
                            </div>
                          )}
                        {isShowLiveClass && (
                          <LiveSessionVideo
                            session_video_set={course.session_video_set}
                            user_data={course.user_data}
                            updateloadvideotime={updateloadvideotime}
                            setUpdateloadvideotime={handleUpdateloadvideotime}
                            lastviewvideotime={lastviewvideotime}
                            setLastviewvideotime={setLastviewvideotime}
                          />
                        )}
                      </Route>
                    </Switch>
                  </div>
                </div>
              </div>
              {!isShowLiveClass && (
                <div className="e-learning-dashboard-section">
                  <div className="row justify-content-md-center">
                    <div className="col-lg-11">
                      <ul className="lms-controller-nav-bar">
                        {course.unit_data.transcribe_status === 2 &&
                          course.unit_data.show_on_site === true && (
                            <li>
                              <a
                                href="javascript:void(0)"
                                onClick={() => setBottomSectionShow(3)}
                                className={
                                  bottomSectionShow === 3
                                    ? "active-lms-controller"
                                    : ""
                                }
                              >
                                Transcript
                              </a>
                            </li>
                          )}
                        <li>
                          <a
                            href="javascript:void(0)"
                            onClick={() => setBottomSectionShow(1)}
                            className={
                              bottomSectionShow === 1
                                ? "active-lms-controller"
                                : ""
                            }
                          >
                            Q&A
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0)"
                            onClick={() => setBottomSectionShow(2)}
                            className={
                              bottomSectionShow === 2
                                ? "active-lms-controller"
                                : ""
                            }
                          >
                            Notes
                          </a>
                        </li>
                      </ul>
                      {bottomSectionShow === 1 && (
                        <SupportForum course_id={course_id} />
                      )}
                      {bottomSectionShow === 2 && (
                        <Notes
                          unit_selected={course.unit_selected}
                          course_id={course_id}
                        />
                      )}
                      {bottomSectionShow === 3 && (
                        <section>
                          <div className="row">
                            <div className="col-lg-12">
                              {parse(`
                                      ${transcribeText}
                                  `)}
                            </div>
                          </div>
                        </section>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {course && showCourseContentRight && (
              <CourseContent
                onclose={() => {
                  setLpanelopen("display-none-side-menu-lms");
                  setMobilePanelClass("");
                }}
                course_info={course}
                opensection={setOpenSection}
                openchapter={setOpenChapter}
                setselectiondata={setSelectionData}
                course_id={course_id}
                unitcomplete={unitComplete}
                mobilePanelClass={mobilePanelClass}
                showLiveClass={showLiveClass}
                setshowCourseContentRight={setshowCourseContentRight}
              />
            )}
            {isShowLiveClass && !showCourseContentRight && (
              <LiveClassRightBar
                onclose={() => {
                  setLpanelopen("display-none-side-menu-lms");
                  setMobilePanelClass("");
                }}
                setIsShowLiveClass={setIsShowLiveClass}
                course_batch_data={course.course_batch_data}
                course_batch_loading={course.course_batch_loading}
                getBatchSessions={getBatchSessions}
                openSession={openSession}
                setSessionVideo={setSessionVideo}
                mobilePanelClass={mobilePanelClass}
                showCourseContentRightBar={showCourseContentRightBar}
              />
            )}

            {coursetest.course_test_started &&
              !coursetest.course_test_submit &&
              !showCourseContentRight && (
                <div>
                  <Modal show={isCancellTest}>
                    <Modal.Header closeButton={false}>
                      <Modal.Title>Cancel Test </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to cancel the test?</Modal.Body>
                    <Modal.Footer>
                      <Button variant="warning" onClick={() => cancelTest()}>
                        Yes
                      </Button>
                      <Button
                        variant="default"
                        onClick={() => setIsCancellTest(false)}
                      >
                        No
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <TestSectionRighrBar
                    course_test_info={coursetest.course_test_data}
                    cancelTest={() => setIsCancellTest(true)}
                    current_question_no={coursetest.current_question_no}
                    setCurrentQuestion={saveAnswerandSetCurrentQuestion}
                  />
                </div>
              )}
            {coursetest.course_test_started &&
              coursetest.course_test_submit &&
              !showCourseContentRight && (
                <div>
                  <TestSectionRightResultBar
                    course_test_result_loading={
                      coursetest.course_test_result_loading
                    }
                    course_test_result={coursetest.course_test_result}
                    totalTimeTaken={totalTimeTaken}
                    setCourseTestStarted={setCourseTestData}
                    course_test_info={coursetest.course_test_data}
                    unit_selected={course.unit_selected}
                    unit_data={course.unit_data}
                    gotoNextUnit={gotoNextUnit}
                    setshowCourseContentRight={setshowCourseContentRight}
                  />
                </div>
              )}
            <Modal
              show={show}
              onHide={handleClose}
              animation={false}
              className="custom-modal"
            >
              <Modal.Header closeButton>
                {course && <Modal.Title>{course.course_name}</Modal.Title>}
              </Modal.Header>
              <Modal.Body>
                {course && (
                  <Rating
                    item_id={course_id}
                    modalClosed={handleClose}
                    item_type={1}
                  />
                )}
              </Modal.Body>
            </Modal>
          </div>
        </Router>
      </main>
    );
  }
}

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import MyCourseIcon from "../images/MyCourseIcon";
import MyWebinarIcon from "../images/MyWebinarIcon";
import MyCertificateIcon from "../images/MyCertificateIcon";
import MyProfileIcon from "../images/MyProfileIcon";
import SupportIcon from "../images/SupportIcon";
import AffiliateIcon from "../images/AffiliateIcon";
import userHeader from "../images/user_default_big.png";
import AuthorDashboard from "../images/AuthorDashboard";
import HostCourse from "../images/HostCourse";
import CourseDiscussion from "../images/CourseDiscussion";
import HostWebinar from "../images/HostWebinar";
import PromotionLink from "../images/PromotionLink";
import UploadProfilePhoto from "../components/UploadProfilePhoto";
import { Link } from "react-router-dom";
import "../styles/host_course.css";
import ElmSchoolIcon from "../images/ElmSchoolIcon";
import WorkshopIcon from "../images/WorkshopIcon";
import MyOfferIcon from "../images/MyOfferIcon";

function LeftPanel(props) {
  const [openModalProfilePic, setOpenModalProfilePic] = useState(false);
  const [isViewAuthor, setIsViewAuthor] = useState(false);
  const [viewTrainee, setViewTrainee] = useState();
  const [viewStudent, setViewStudent] = useState();
  useEffect(() => {
    if (!props.userDataLoading) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        userId: props.userInfo[0].id, //this number must be replaced with an actual User ID
      });
    }
    if (!props.userDataLoading) {
     
        handleViewAuthor(
          props.userInfo[0].is_author,false
        );
      
   
    }
  }, [props.userDataLoading]);
  const handleViewAuthor = (value,callFormMenu) => {
    
    //setIsViewAuthor(value)
    
   
   let setvalue= value
    if(callFormMenu){
      setvalue = value
      
    }else{
      setvalue = props.callFromAuthor
    }
    if(setvalue === false){
      setViewTrainee('')
      setViewStudent('active')
      setIsViewAuthor(setvalue)
    }
    else{
      setViewStudent('');
      setViewTrainee('active')
      setIsViewAuthor(setvalue)
      
    }
    
  }

  return (
    <div className="col-lg-3">
      <div
        onClick={() => props.onMobileMenuClick(false)}
        className={
          props.isMobileBarMenuClicked
            ? "left-menu-overley show-overley"
            : "left-menu-overley"
        }
      ></div>
      <div
        className={
          props.isMobileBarMenuClicked
            ? "left-menu-mobile open-left-menu"
            : "left-menu-mobile"
        }
      >
        <div className="left-panel-my-account">
          <div className="user-profile-img">
            <img
              alt=""
              lazy
              src={
                !props.userDataLoading
                  ? props.userInfo[0].photo
                    ? props.userInfo[0].photo
                    : userHeader
                  : ""
              }
              className="img-fluid"
            />
            <a
              data-tip="change profile image"
              className="change-profile-picture"
              style={{ cursor: "pointer" }}
              onClick={() => setOpenModalProfilePic(true)}
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </a>
            {openModalProfilePic && (
              <UploadProfilePhoto
                show={openModalProfilePic}
                onclose={() => setOpenModalProfilePic(false)}
                getUserData={props.getUserData}
              />
            )}
          </div>
          {!props.userDataLoading && props.userInfo[0].is_author === true && (
            <div className="user-name">
              <span>
                {!props.userDataLoading
                  ? props.userInfo[0].first_name +
                    " " +
                    props.userInfo[0].last_name
                  : ""}
              </span>
              <p className="author-and-student-btn">
                <Link
                  className={viewStudent}
                  onClick={() => {
                    handleViewAuthor(false,true);
                  }}
                >
                  View as Student
                </Link>
                <Link
                  className={viewTrainee}
                  onClick={() => {
                    handleViewAuthor(true,true);
                  }}
                >
                  View as Trainer
                </Link>
              </p>
            </div>
          )}
          {!props.userDataLoading && props.userInfo[0].is_author === false && (
            <div className="user-name">
              <span>
                {!props.userDataLoading
                  ? props.userInfo[0].first_name +
                    " " +
                    props.userInfo[0].last_name
                  : ""}
              </span>
              
            </div>
          )}
          <ul className="side-menu-account">
            {!props.userDataLoading &&
              props.userInfo[0].is_author === true &&
              isViewAuthor && (
                <li>
                  <NavLink to="/dashboard">
                    <AuthorDashboard />
                    Dashboard
                  </NavLink>
                </li>
              )}
            {!isViewAuthor && (
              <>
                <li>
                  <NavLink to="/my-courses" activeClassName="active">
                    <MyCourseIcon />
                    My Courses
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/my-webinars">
                    <MyWebinarIcon />
                    My Webinars
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/my-certificates">
                    <MyCertificateIcon />
                    My Certificates
                  </NavLink>
                </li>
                <li>
                <NavLink to="/elm-school">
                  <ElmSchoolIcon />
                  Elm School
                </NavLink>
            </li>
            <li>
                <NavLink to="/my-events">
                  <WorkshopIcon />
                  My Events
                </NavLink>
            </li>
            <li>
              <NavLink to="/my-offers">
                <MyOfferIcon />
                My Offers
              </NavLink>
            </li>
              </>
            )}
            {!props.userDataLoading && props.userInfo[0].is_affiliate === true && (
              <li>
                <NavLink to="/affiliate">
                  <AffiliateIcon />
                  Affiliates
                </NavLink>
              </li>
            )}
            {!props.userDataLoading &&
              props.userInfo[0].is_author === true &&
              isViewAuthor && (
                <>
                <li>
                    <NavLink to="/author-link">
                      <PromotionLink />
                      Promotion Link
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/hosted-courses">
                      <HostCourse />
                       Courses
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/hosted-webinars">
                      <HostWebinar />
                       Webinars
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/discussion">
                      <CourseDiscussion />
                      Course Discussion
                    </NavLink>
                  </li>
                </>
              )}
           
            {!isViewAuthor && (
            <>
             <li>
              <NavLink to="/support">
                <SupportIcon />
                Support
              </NavLink>
            </li>
            <li>
              <NavLink to="/profile">
                <MyProfileIcon />
                Profile
              </NavLink>
            </li>
            </>
            )}

            {isViewAuthor && (
            <>
            <li>
              <NavLink to="/author-profile">
                <MyProfileIcon />
                Author Profile
              </NavLink>
            </li>
            </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LeftPanel;

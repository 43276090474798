import { actionconstants } from '../actionconstants'
import initialstate from '../initialstate'


export default function courseTestReducer(state = initialstate, action) {
    switch (action.type) {
        case actionconstants.LOADING_COURSE_TEST_DATA:
            return {
                ...state,
                course_test_loading: true,
                course_test_started: false,
                course_test_submit: false,
                course_test_result_loading: false
            }
        case actionconstants.LOADING_COURSE_TEST_DATA_SUCCESS:
            return {
                ...state,
                course_test_data: [action.payload.data],
                course_test_data_success_value: action.payload.success,
                course_test_all_data: action.payload,
                course_test_loading: false,
                course_test_started: false,
                course_test_submit: false,
                course_test_result_loading: false,
                current_question_no: 0,
            }
        case actionconstants.COURSE_TEST_STARTED:
            return {
                ...state,
                course_test_started: true
            }
        case actionconstants.COURSE_TEST_GET_NEXT_QUESTION:
            return {
                ...state,
                next_question_loading: true,
            }
        case actionconstants.COURSE_TEST_SAVE_ANSWER_AND_SET_QUESTION:
            let all_test_question = [...state.course_test_data]
            if (action.payload.answerObj.hasOwnProperty('answerid') && action.payload.answerObj.answerid !== false) {
                let current_question = all_test_question[0].glossary_questions[action.payload.current_selected_question_index]
                let answer = current_question.glossary_answers.filter((arr) => { return arr.id === action.payload.answerObj.answerid })
                answer[0].is_correct = 1
                current_question.is_attempted = 1
            }
            let cqno
            if (action.payload.answerObj.is_next === 1) {
                cqno = action.payload.current_selected_question_index + 1
            } else if (action.payload.answerObj.is_next === 0) {
                cqno = action.payload.current_selected_question_index - 1
            } else if (action.payload.answerObj.is_next === 2) {
                cqno = action.payload.current_selected_question_index
            }
            return {
                ...state,
                course_test_data: all_test_question,
                current_question_no: cqno,
                next_question_loading: false,
            }
        case actionconstants.COURSE_TEST_FINISHED:
            return {
                ...state,
                course_test_submit: true,
                course_test_result_loading: true,
            }
        case actionconstants.COURSE_TEST_RESULT_GENERATED:
            return {
                ...state,
                course_test_submit: true,
                course_test_result_loading: false,
                course_test_result: action.payload
            }
        case actionconstants.COURSE_TEST_CANCELLED:
            return {
                ...state,
                course_test_started: false,
            }
        default:
            return {
                ...state
            }
    }
}
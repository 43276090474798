import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-seek-buttons/dist/videojs-seek-buttons.css'

import videoJsContribQualityLevels from 'videojs-contrib-quality-levels'
import videojsHlsQualitySelector from 'videojs-hls-quality-selector'
import seekButtons from 'videojs-seek-buttons'
videojs.registerPlugin('qualityLevel', videoJsContribQualityLevels)
videojs.registerPlugin('hlsQualitySelector', videojsHlsQualitySelector)
videojs.registerPlugin('seekButtons', seekButtons)
const usePlayer = ({ src, controls, autoplay,onloadeddata,ontimeupdate,lastPlayTime }) => {
    const options = {
    fill: true,
    fluid: false,
    preload: 'auto',
    responsive: true,
    html5: {
      hls: {
        enableLowInitialPlaylist: true,
        smoothQualityChange: true,
        overrideNative: true,
      },
    },
    plugins: {
      qualityLevel: {},
      hlsQualitySelector: { displayCurrentQuality: true },
      seekButtons: {
        forward: 30,
        back: 10,
      }
    }
  };
  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, {
      ...options,
      controls,
      autoplay,
      playbackRates:[0.5,1,1.25,1.5,2],
      sources: [src],
    });

    vjsPlayer.on('loadeddata', () => {
      onloadeddata(vjsPlayer.duration())
      
      vjsPlayer.currentTime(lastPlayTime)
      
    })
    vjsPlayer.on('timeupdate', () => {
      ontimeupdate(vjsPlayer.duration(),vjsPlayer.currentTime())
    })

    setPlayer(vjsPlayer);

    return () => {
      if (player !== null) {
        player.dispose();
      }
    };
  }, []);
  useEffect(() => {
    if (player !== null) {
      player.src({ src });
    }
  }, [src]);

  return videoRef;
};

const VideoJsPlayer = ({ src, controls, autoplay,onloadeddata,ontimeupdate,poster,lastPlayTime,customText }) => {
  useEffect(()=>{
    
  },customText)
  const playerRef = usePlayer({ src, controls, autoplay,onloadeddata,ontimeupdate,lastPlayTime,customText });

  return (
    <div data-vjs-player>
      <video ref={playerRef} poster={poster}  className="video-js vjs-fill vjs-default-skin vjs-big-play-centered" >


      </video>
      
      

    </div>
  );
};

VideoJsPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  controls: PropTypes.bool,
  autoplay: PropTypes.bool,
};

VideoJsPlayer.defaultProps = {
  controls: true,
  autoplay: true,
  lastPlayTime:0,
  customText:""


};

export default React.memo(VideoJsPlayer);
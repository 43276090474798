import React from 'react';
import LoadingOverlay from 'react-loading-overlay'
import MoonLoader from 'react-spinners/MoonLoader'

function Loader(props) {
    let h = window.screen.availHeight
    return (
        <div style={{height: h / 2}}>
            <div className="row">
                <div className="col-lg-12">
                    <div style={{marginTop:(h / 4)- 25}}>
                      <LoadingOverlay
                        active={props.is_loading}
                        spinner={<MoonLoader color="#ee9949" />}
                      />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loader;

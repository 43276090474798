import React from "react";

const TestSectionRightResultBar = (props) => {
  const getRemainToHHMMSS = (remaining) => {
    let secData = remaining;
    let resultText;
    let hours =
      Math.floor(secData / 3600) < 10
        ? ("00" + Math.floor(secData / 3600)).slice(-2)
        : Math.floor(secData / 3600);
    let minutes = ("00" + Math.floor((secData % (60 * 60)) / 60)).slice(-2);
    let seconds = ("00" + ((secData % 3600) % 60)).slice(-2);
    if (Number(hours) > 0) {
      resultText = hours + ":" + minutes + ":" + seconds;
    } else {
      resultText = minutes + ":" + seconds;
    }
    return resultText;
  };
  function getTotalScore() {
    return (
      props.course_test_info[0].each_question_mark *
      props.course_test_info[0].glossary_questions.length
    );
  }

  function getBtnClass(questionObj) {
    let c;
    let class_name = "";
    if (questionObj.is_attempted === 0) {
      class_name = "";
    } else {
      c = props.course_test_result.data.user_glossary_answers.filter((arr) => {
        return arr.glossary_question_id === questionObj.id;
      });
      if (c[0].is_correct === true) {
        class_name = "correct-q";
      } else if (c[0].is_correct === false && questionObj.is_attempted === 1) {
        class_name = "wrong-q";
      }
    }
    return class_name;
  }

  function goNext() {
    props.setshowCourseContentRight(true);
    props.gotoNextUnit(1);
  }

  return (
    !props.course_test_result_loading && (
      <div
        className="right-section-for-elearn for-test-section"
        style={{ overflowY: "scroll" }}
      >
        <div className="side-menu-for-lms ">
          <div className="total-score-box">
            <p>
              <span className="pull-left">Your score</span>
              <span className="pull-right">
                {props.course_test_result.data.marks_obtained}
              </span>
            </p>
            <p>
              <span className="pull-left">Total Score</span>
              <span className="pull-right">{getTotalScore()}</span>
            </p>
            <p>
              <span className="pull-left">Time Taken</span>
              <span className="pull-right">
                {getRemainToHHMMSS(props.totalTimeTaken)}
              </span>
            </p>
          </div>
          <div className="number-of-questions-ans">
            <h2>
              Result <span>Overview</span>
            </h2>
            <div className="total-question-number-box">
              {props.course_test_info[0].glossary_questions.map(
                (item, index) => (
                  <button className={getBtnClass(item)}>{index + 1}</button>
                )
              )}
            </div>
            <div className="question-Attempted-box">
              <ul>
                <li>
                  <span className="correct-q"></span> Correct
                </li>
                <li>
                  <span className="wrong-q"></span> Wrong
                </li>
                <li>
                  <span></span> Not Attempted
                </li>
              </ul>
            </div>
            <p className="text-center mt-4 mb-4">
              <button
                className="btn test-submit-btn"
                onClick={() =>
                  props.setCourseTestStarted(
                    props.unit_data.glossary_id,
                    props.unit_selected
                  )
                }
              >
                retest
              </button>
              <button
                className="btn test-submit-btn ml-2"
                style={{ background: "#2e5dad" }}
                onClick={() => goNext()}
              >
                Next Lesson
              </button>
            </p>
          </div>
          <div className="Leaderboard-section">
            <h2>Leaderboard</h2>

            <table>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Score</th>
                <th>Time</th>
              </tr>
              {props.course_test_result.user_data.map((item, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>
                    {item.user.first_name} {item.user.last_name}
                  </td>
                  <td>{item.marks_obtained}</td>
                  <td>{getRemainToHHMMSS(item.time_taken)}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    )
  );
};

export default TestSectionRightResultBar;

import React from "react";
import { Scrollbar } from "react-scrollbars-custom";
import Moment from "moment";

const LiveClassRightBar = (props) => {
  return (
    <div className={`right-section-for-elearn ${props.mobilePanelClass}`}>
      <div className="side-menu-for-lms">
        {props.course_batch_loading && <p>Loading</p>}
        {!props.course_batch_loading && (
          <Scrollbar>
            <p className="top-header-lms-menu" style={{ fontSize: "12px" }}>
              <span
                className="top-header-lms-menu-tabs"
                style={{ cursor: "pointer" }}
                onClick={() => props.showCourseContentRightBar()}
              >
                Course Content
              </span>
              <span
                className="top-header-lms-menu-tabs"
                style={{ backgroundColor: "#EE9949", color: "#fff" }}
              >
                Live Sessions
              </span>
              <a
                onClick={props.onclose}
                className="close-btn-for-lms-menu"
              >
                <i className="fa fa-times" aria-hidden="true"></i>
              </a>
            </p>
            {props.course_batch_data.length > 0 &&
              props.course_batch_data.map((item, index) => (
                <div key={index}>
                  <div
                    className="course-content-list-active"
                    onClick={() => props.getBatchSessions(item.id)}
                  >
                    <span>{item.name}</span>
                    <span className="pl-3 pull-right side-menu-navigation">
                      <i
                        className={
                          item.batch_session_loaded && item.batch_session_opened
                            ? "icon-small fa fa-chevron-up"
                            : "icon-small fa fa-chevron-down"
                        }
                      ></i>
                    </span>
                  </div>
                  {item.batch_session_loaded &&
                    item.batch_session_opened &&
                    item.batch_sessions.map((batch_session, idx) => (
                      <div key={idx} className="chapter-block">
                        <div
                          className="chapter-box"
                          onClick={() => props.openSession(index, idx)}
                        >
                          <span className="chapter-name">
                            {batch_session.name}
                          </span>
                          <span className="pull-right chapter-collapse">
                            <i
                              className={
                                batch_session.is_open
                                  ? "icon-small fa fa-minus"
                                  : "icon-small fa fa-plus"
                              }
                            ></i>
                          </span>
                        </div>
                        {batch_session.is_open &&
                          batch_session.data.map((session, sidx) => (
                            <div key={sidx}>
                              <div
                                className="unit-name"
                                style={{ cursor: "pointer" }}
                                onClick={() => props.setSessionVideo(session)}
                              >
                                <div className="unit-name-box">
                                  <i
                                    className="fa fa-video-camera"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="unit-name-text">
                                    {session.name}
                                  </span>
                                  <span className="video-time">
                                    <i
                                      className="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {Moment(new Date(session.start_date)).utc().format(
                                      "Do MMM YYYY"
                                    )}{" "}
                                    <i
                                      className="fa fa-clock-o"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {session.start_time}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              ))}
          </Scrollbar>
        )}
      </div>
    </div>
  );
};
export default LiveClassRightBar;

import { myAccountActionConstants } from "../myAccountActionConstants";
import { fetchDataWithCsrf,getDataWithToken } from "../../services/fetchDataWithCsrf";

const getMyCourses = () => {
  return async (dispatch) => {
    await dispatch(loadingMyCourse());
    let d = await getDataWithToken("home/get-user-purchased-courses");
    dispatch(loadingMyCourseSuccess(d.authorPurchasedCourses));
  };
};

const fetchPostData = async (url, data) => {
  // let csrfToken = getCookie("csrfToken");
  // const requestOptions = {
  //   method: "POST",
  //   headers: { "Content-Type": "application/json", "X-CSRF-TOKEN": csrfToken },
  //   credentials: "include",
  //   body: JSON.stringify(data),
  // };
  // let r = await fetch(siteConstants.API_URL + url, requestOptions);
  let r1 = await fetchDataWithCsrf(data, url);
  return r1;
};
const loadingMyCourse = () => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_COURSE_LOADING,
  };
};

const loadingMyCourseSuccess = (data) => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_COURSE_COMPLETE,
    payload: data,
  };
};
//For fetch webinar Section getUserPurchasedWebinar past
const getMyWebinars = (page,data) => {
  return async (dispatch) => {
    await dispatch(loadingMyWbinar());
    let d = await fetchDataWithCsrf(data,"home/get-user-webinar?page=" + page);
    dispatch(loadingMyWebinarSuccess(d.user_webinar, d.paging));
  };
};
const getMyWebinarPaging = (page,data) => {
  return async (dispatch) => {
    dispatch(loadingMyWbinarPaging());
    let d = await fetchDataWithCsrf(data,"home/get-user-webinar?page=" + page);
    dispatch(loadingMyWebinarSuccessPaging(d.user_webinar, d.paging));
  };
};
const getMyUpcomingWebinars = () => {
  return async (dispatch) => {
    await dispatch(loadingMyUpcomingWbinar());
    let d = await getDataWithToken("home/get-user-upcoming-webinar");
    dispatch(loadingMyUpcomingWebinarSuccess(d.user_webinar));
  };
};
const getAvarageRating = (item_id, item_type) => {
  return async (dispatch) => {
    await dispatch(loadingAvarageRating());
    let d = await fetchDataWithCsrf({
      object_id: item_id,
      object_type: item_type,
    }, 'webinars/getAvjRating')
    dispatch(loadingAvarageRatingSuccess(d));
  };
};

const getUserRating = (item_id, item_type) => {
  return async (dispatch) => {
    await dispatch(loadingRating());
    let d = await fetchDataWithCsrf({item_id: item_id,
      item_type: item_type}, 'webinars/getReviewData')
    dispatch(loadingRatingSuccess(d.data));
  };
};
const getAllRating = (item_id, item_type) => {
  return async (dispatch) => {
    await dispatch(loadingAllRating());
    let d = await fetchDataWithCsrf( {
      object_id: item_id,
      object_type: item_type,
    },"webinars/getAllObjectReviews?page=1");
    dispatch(loadingAllRatingSuccess(d.data, d.paging));
  };
};
const likeReview = (data, index) => {
  return async (dispatch) => {
    await dispatch(loadingLike());
    let d = await fetchPostData("webinars/likeReview", data);
    dispatch(loadingLikeSuccess(d, index, data.like_type));
  };
};
const loadingLike = () => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_LIKE_LOADING,
  };
};
const loadingLikeSuccess = (data, index, type) => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_LIKE_COMPLETE,
    payload: { data: data, index: index, type: type },
  };
};
const loadingAvarageRating = () => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_AVARAGE_RATING_LOADING,
  };
};
const loadingAvarageRatingSuccess = (data) => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_AVARAGE_RATING_COMPLETE,
    payload: data,
  };
};
const getAllRatingPaging = (item_id, item_type, page) => {
  return async (dispatch) => {
    await dispatch(loadingAllRatingPaging());
    let d = await fetchPostData("webinars/getAllObjectReviews?page=" + page, {
      object_id: item_id,
      object_type: item_type,
    });
    dispatch(loadingAllRatingSuccessPaging(d.data, d.paging));
  };
};
const loadingAllRatingPaging = () => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_ALL_PAGING_RATING_LOADING,
  };
};
const loadingAllRatingSuccessPaging = (data, paging) => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_ALL_PAGING_RATING_COMPLETE,
    payload: data,
    pagination: paging,
  };
};

const loadingAllRating = () => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_ALL_RATING_LOADING,
  };
};
const loadingAllRatingSuccess = (data, paging) => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_ALL_RATING_COMPLETE,
    payload: data,
    pagination: paging,
  };
};
const loadingRating = () => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_RATING_LOADING,
  };
};
const loadingRatingSuccess = (data) => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_RATING_COMPLETE,
    payload: data,
  };
};

const loadingMyWbinar = () => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_WEBINAR_LOADING,
  };
};
const loadingMyWebinarSuccess = (data, paging) => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_WEBINAR_COMPLETE,
    payload: data,
    pagination: paging,
  };
};
const loadingMyWbinarPaging = () => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_WEBINAR_LOADING_PAGING,
  };
};
const loadingMyWebinarSuccessPaging = (data, paging) => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_WEBINAR_COMPLETE_PAGING,
    payload: data,
    pagination: paging,
  };
};
const loadingMyUpcomingWbinar = () => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_UPCOMING_WEBINAR_LOADING,
  };
};
const loadingMyUpcomingWebinarSuccess = (data) => {
  return {
    type: myAccountActionConstants.LOADING_MYACCOUNT_UPCOMING_WEBINAR_COMPLETE,
    payload: data,
  };
};

const getUserDetails = (fetch_from_api = false) => {
  return async (dispatch) => {
    let u_info = sessionStorage.getItem("userInfo");
    let fl = false;
    if (u_info !== null && !fetch_from_api) {
      let u = JSON.parse(u_info);
      if (u.length > 0) {
        fl = true;
        dispatch(userDetailSuccess(JSON.parse(u_info)));
      }
    }
    if (!fl) {
      dispatch(useDetailLoading());
      let d = await fetchUser();
      if (d.success === 0) {
        dispatch(loginError());
      } else {
        sessionStorage.setItem("userInfo", JSON.stringify(d.data));
        dispatch(userDetailSuccess(d.data));
      }
    }
  };
};

const getUserAllDetails = () => {
  return async (dispatch) => {
      dispatch(useAllDetailLoading());
      let d = await fetchUserDetails();
        dispatch(userAllDetailSuccess(d));
      
    }
  };


const useDetailLoading = () => {
  return {
    type: myAccountActionConstants.LOADING_USER_INFO,
  };
};

const useAllDetailLoading =() => {
  return {
    type: myAccountActionConstants.LOADING_USER_DETAILS,
  };
}
const fetchUser = async () => {
  let r = await fetchDataWithCsrf(null, "login/getUserData");
  return r;
};

const fetchUserDetails = async () => {
  let r = await fetchDataWithCsrf(null, "login/user-profile");
  return r;
};
const userDetailSuccess = (data) => {
  return {
    type: myAccountActionConstants.LOADING_USER_INFO_COMPLETE,
    payload: data,
  };
};

const userAllDetailSuccess = (data) => {
  return {
    type: myAccountActionConstants.LOADING_USER_DETAILS_COMPLETE,
    payload: data,
  };
};

const loginError = () => {
  return {
    type: myAccountActionConstants.USER_NOT_LOGGGED_IN,
  };
};
export const myaccountAction = {
  getMyCourses,
  getMyWebinars,
  getUserRating,
  getMyUpcomingWebinars,
  getAvarageRating,
  getAllRating,
  getAllRatingPaging,
  getMyWebinarPaging,
  getUserDetails,
  likeReview,
  getUserAllDetails,
};

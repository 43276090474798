import React, { Component } from 'react'
import MainComponent from './components/MainComponent'

class Learn extends Component {

  constructor(props) {
    super(props)
    const { match: { params } } = this.props;
    localStorage.setItem('course_id', params.id)
    this.state = {
      data: [],
      redirectToReferrer: false,
      name: '',
      course_id_from_params: params.id
    };
  }
  render() {
    return (
      <MainComponent 
        course_id_from_params={this.state.course_id_from_params}
      />
    )
  }
}

export default Learn;

import React, { useState, useEffect, useRef } from "react";
import parse from "html-react-parser";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Modal } from "react-bootstrap";

const CourseTestResult = (props) => {
  let [scrollYTop, setScrollYTop] = useState(0);
  const Answers = (cprops) => {
    const scrollDemoRef = useRef(null);
    useEffect(() => {
      if (!cprops.showDescriptionModal) {
        if (scrollDemoRef.current) {
          scrollDemoRef.current.scroll(0, scrollYTop);
        }
      }
    }, [cprops.showDescriptionModal]);

    function setCorrectQ(ansobj) {
      let c = props.course_result_data.data.user_glossary_answers.filter(
        (arr) => {
          return arr.correct_answer === ansobj.id;
        }
      );
      let fl = 0;
      if (ansobj.is_correct === 1) {
        fl = 1; //attempted
      }
      if (c.length > 0) {
        fl = 2; //correct answer
      }

      return fl;
    }
    /**
     *
     * @param {*} is_show (0=>all answers, 1=>all correct, 2=>all incorrect, 3=>not attempted)
     */
    function isShow(is_show = 0, questionObj) {
      let c;
      let fl = false;
      if (is_show === 0) {
        fl = true;
      }
      if (is_show === 1 || is_show === 2) {
        c = props.course_result_data.data.user_glossary_answers.filter(
          (arr) => {
            return arr.glossary_question_id === questionObj.id;
          }
        );
        if (c[0].is_correct === true && is_show === 1) {
          fl = true;
        }
        if (
          c[0].is_correct === false &&
          is_show === 2 &&
          questionObj.is_attempted === 1
        ) {
          fl = true;
        }
      }

      if (is_show === 3) {
        if (questionObj.is_attempted !== 1) {
          fl = true;
        }
      }
      return fl;
    }
    function manageDescription(filecontent) {
      setScrollYTop(scrollDemoRef.current.scrollTop);
      cprops.showDescription(filecontent);
    }

    return (
      <div
        className={`course-result-container`}
        ref={scrollDemoRef}
        style={{ overflowY: `scroll` }}
      >
        <span>Test Name: {cprops.test_name}</span>
        {cprops.course_test_info.map((gl, i) => (
          <div>
            {isShow(cprops.tabShow, gl) && (
              <div className="course-test" style={{ marginBottom: "10px" }}>
                <p class="course-test-name">
                  Question {i + 1} of {cprops.course_test_info.length}
                </p>
                <div className="course-test-container">
                  <p>{parse(`${gl.question}`)}</p>
                  {gl.glossary_answers.map((ans, i) => (
                    <div className="course-test-option">
                      <p>{parse(`${ans.q_option}`)}</p>
                      {setCorrectQ(ans) > 0 && (
                        <span
                          className={
                            setCorrectQ(ans) === 1
                              ? "for-wrong-answer"
                              : setCorrectQ(ans) === 2
                              ? "for-correct-answer"
                              : ""
                          }
                        >
                          <i
                            className={
                              setCorrectQ(ans) === 1
                                ? "fa fa-times"
                                : setCorrectQ(ans) === 2
                                ? "fa fa-check"
                                : ""
                            }
                            aria-hidden="true"
                          ></i>
                        </span>
                      )}
                    </div>
                  ))}
                </div>
                {gl.filecontent_id > 0 && (
                  <span
                    className="btn"
                    style={{ cursor: "pointer", background: "#ee9949" }}
                    onClick={() => manageDescription(gl.filecontent)}
                  >
                    Show description
                  </span>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const [key, setKey] = useState("all_ans");
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [description, setDescription] = useState(null);
  const showDescription = (html) => {
    setDescription(html.unit_html);
    setShowDescriptionModal(true);
  };
  const closeModal = () => {
    setDescription(null);
    setShowDescriptionModal(false);
  };
  return (
    <div>
      <Tabs
        id="test_answers_tab"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="test-result-tab"
      >
        <Tab eventKey="all_ans" title="All Answers">
          <Answers
            course_test_info={props.course_test_info}
            test_name={props.test_name}
            tabShow={0}
            showDescription={showDescription}
            showDescriptionModal={showDescriptionModal}
          />
        </Tab>
        <Tab eventKey="cor_ans" title="Correct Answers">
          <Answers
            course_test_info={props.course_test_info}
            test_name={props.test_name}
            tabShow={1}
            showDescription={showDescription}
            showDescriptionModal={showDescriptionModal}
          />
        </Tab>
        <Tab eventKey="incr_ans" title="Incorrect Answers">
          <Answers
            course_test_info={props.course_test_info}
            test_name={props.test_name}
            tabShow={2}
            showDescription={showDescription}
            showDescriptionModal={showDescriptionModal}
          />
        </Tab>
        <Tab eventKey="nat_ans" title="Not Attempted">
          <Answers
            course_test_info={props.course_test_info}
            test_name={props.test_name}
            tabShow={3}
            showDescription={showDescription}
            showDescriptionModal={showDescriptionModal}
          />
        </Tab>
      </Tabs>
      <Modal show={showDescriptionModal}>
        <Modal.Body>{description}</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-default" onClick={() => closeModal()}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CourseTestResult;
